import { createAsyncThunk } from '@reduxjs/toolkit'
import { menuApi } from '../../../api/apis'
import { MenuItemDto } from '../../../generated/backend'

export const getMenuTree = createAsyncThunk(
  'menu/getAll',
  async (expandedNodes: number[], { rejectWithValue }) => {
    try {
      const nodes = expandedNodes.map(node => node.toString());
      const res = await menuApi.apiTreeMenuItemGet(nodes);
      return res.data;
    } catch (e) {
      rejectWithValue(e.message);
    }
  },
);

export const getMenuItem = createAsyncThunk(
  'menu/getItem',
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await menuApi.apiMenuItemIdGet(id);
      return res.data;
    } catch (e) {
      rejectWithValue(e.message);
    }
  },
);

export const createMenuItem = createAsyncThunk(
  'menu/create',
  async (newItem: MenuItemDto, { rejectWithValue }) => {
    try {
      const res = await menuApi.apiMenuItemPost(newItem);
      return res.data;
    } catch (e) {
      rejectWithValue(e.message);
    }
  }
)

export const deleteMenuItem = createAsyncThunk(
  'menu/delete',
  async (id: number, { rejectWithValue }) => {
    try {
      const res = await menuApi.apiMenuItemIdDelete(id.toString());
      return res.data;
    } catch (e) {
      rejectWithValue(e.message);
    }
  },
);
