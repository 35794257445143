import { createSlice } from '@reduxjs/toolkit'
import { SettingsState } from './types'
import { getAllSettings, getSetting, updateSetting } from './settingsActions'

const initialState: SettingsState = {
  selected: {
    loading: true,
    setting: {},
  },
  tree: {
    loading: true,
    settings: [],
  },
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAllSettings.pending, (state) => {
      state.tree.loading = true;
    });
    builder.addCase(getAllSettings.fulfilled, (state, action) => {
      state.tree.settings = action.payload;
      state.tree.loading = false;
    });

    builder.addCase(getSetting.pending, (state) => {
      state.selected.loading = true;
    });
    builder.addCase(getSetting.fulfilled, (state, action) => {
      state.selected.setting = action.payload;
      state.selected.loading = false;
    });

    builder.addCase(updateSetting.pending, (state) => {
      state.selected.loading = true;
    });
    builder.addCase(updateSetting.fulfilled, (state, action) => {
      state.selected.setting = action.payload;
      state.selected.loading = false;
    });
  },
});

export default settingsSlice.reducer;
