import React, { FC, useEffect } from 'react'
import { Tree } from 'antd'
import { NewsPageDto } from '../../../generated/backend'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { deleteNewsPage, getAllNewsPages } from '../../../store/slices/newsPages/newsPagesActions'
import TreeItem from '../../common/forTree/TreeItem'
import { useNavigate } from 'react-router-dom'
import { Loader } from '../../common/Loader/Loader'

const NewsPageTree: FC = () => {
  const dispatch = useAppDispatch();
  const { tree } = useAppSelector((state) => state.news)
  const { pages, loading, } = tree;
  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      await dispatch(getAllNewsPages());
    })()
  }, [dispatch]);

  const onSelect = (item: NewsPageDto) => {
    navigate(`/news-page/${item.id}`);
  }

  const onDeleteNewsPage = async (item: NewsPageDto) => {
    await dispatch(deleteNewsPage(item.id));
    navigate('/news-page/');
    await dispatch(getAllNewsPages());
  };

  if (loading && !pages.length) {
    return <Loader />
  }

  return (
    <Tree
      treeData={pages?.map(item => ({
        id: item.id,
        title: <TreeItem itemName={item.name} onDelete={() => onDeleteNewsPage(item)} />,
        key: item.id,
      }))}
      onSelect={(key, info) => {
        onSelect(info.node)
      }}
    />
  )
}

export default NewsPageTree
