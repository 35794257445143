import * as React from 'react';
import { ChangeEvent, FC, useEffect, useState } from 'react'
import {
  Form,
  message,
  Row,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { BlockDto } from '../../generated/backend';
import { Loader } from '../common/Loader/Loader';
import { blockApi } from '../../api/apis'
import BlockDragger from './blockForm/BlockDragger'
import ButtonGroup from '../common/forForm/ButtonGroup'
import FormInput from '../common/forForm/FormInput'
import FormFilesTable from './blockForm/FormFilesTable'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { getAllBlocks, getBlock } from '../../store/slices/block/blocksActions'
import { clearSelected } from '../../store/slices/block/blockSlice'

export const BlocksForm: FC = () => {
  const { block, loading } = useAppSelector((state) => state.blocks.selected);
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState<BlockDto>(undefined);
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      await dispatch(getBlock(Number(id)));
    })()
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(clearSelected())
    }
  }, [dispatch])

  useEffect(() => {
    setData(block);
  }, [block])

  const onSubmit = async () => {
    await blockApi.apiBlockPut(data);
    message.success('Сохранено!');
    await dispatch(getAllBlocks());
  };

  const onCloseHandler = () => {
    navigate('/blocks');
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [e.target.name]: e.target.value || '',
    })
  };

  if (!data || loading) {
    return <Loader />;
  }

  return (
    <div>
      <Form labelCol={{ span: 2, offset: 0 }} labelAlign={'left'}>
        <ButtonGroup onSubmit={onSubmit} onClose={onCloseHandler} />
        <Row>
          <FormInput fieldName={'name'} value={data?.name} onChange={onInputChange} />

          <FormInput fieldName={'heading1'} value={data?.heading1} onChange={onInputChange} />

          <FormInput fieldName={'heading2'} value={data?.heading2} onChange={onInputChange} />

          <FormInput fieldName={'text'} value={data?.text} onChange={onInputChange} />
        </Row>
      </Form>

      <FormFilesTable id={Number(id)} data={data} />

      <section
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <BlockDragger id={id} />
      </section>
    </div>
  );
};
