import {
  PageApiFactory,
  NewsPageApiFactory,
  FileApiFactory,
  BlockApiFactory,
  PartnerApiFactory,
  MenuItemApiFactory,
  ReviewApiFactory,
  UserApiFactory,
  SectionsApiFactory,
  SettingApiFactory,
} from '../generated/backend';

export const pageApi = PageApiFactory({
  isJsonMime: (mime: string): boolean => false,
  basePath: window.location.origin,
});

export const newsPageApi = NewsPageApiFactory({
  isJsonMime: (mime: string): boolean => false,
  basePath: window.location.origin,
});

export const fileApi = FileApiFactory({
  isJsonMime: (mime: string): boolean => false,
  basePath: window.location.origin,
});

export const blockApi = BlockApiFactory({
  isJsonMime: (mime: string): boolean => false,
  basePath: window.location.origin,
});

export const partnerApi = PartnerApiFactory({
  isJsonMime: (mime: string): boolean => false,
  basePath: window.location.origin,
});

export const menuApi = MenuItemApiFactory({
  isJsonMime: (mime: string): boolean => false,
  basePath: window.location.origin,
});

export const reviewApi = ReviewApiFactory({
  isJsonMime: (mime: string): boolean => false,
  basePath: window.location.origin,
});

export const userApi = UserApiFactory({
  isJsonMime: (mime: string): boolean => false,
  basePath: window.location.origin,
});

export const sectionsApi = SectionsApiFactory({
  isJsonMime: (mime: string): boolean => false,
  basePath: window.location.origin,
});

export const settingApi = SettingApiFactory({
  isJsonMime: (mime: string): boolean => false,
  basePath: window.location.origin,
});
