import React, { FC } from 'react'
import AddDialog from '../../common/AddDialog/AddDialog'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { menuApi } from '../../../api/apis'
import { MenuItemDto } from '../../../generated/backend'
import { getMenuTree } from '../../../store/slices/menu/menuActions'
import { useNavigate } from 'react-router-dom'

interface MenuAddDialog {
  closeModal: () => void;
  isOpen: boolean;
  updateExpandedKeys: (_keys: number[]) => void;
  expandedKeys: number[];
}

const MenuAddDialog: FC<MenuAddDialog> = (props) => {
  const { closeModal, isOpen, expandedKeys, updateExpandedKeys, } = props;
  const { selected, tree,  } = useAppSelector(state => state.menu);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSave = async (item: MenuItemDto) => {
    const response = await menuApi.apiMenuItemPost(item);
    if (selected.item) {
      updateExpandedKeys([...expandedKeys, selected.item.id]);
    }
    navigate(`/menu/${response.data.id}`);
    closeModal();
    await dispatch(getMenuTree(tree.expanded));
  };

  return (
    <AddDialog
      isOpen={isOpen}
      parent={selected.item}
      onClose={closeModal}
      onSave={onSave}
    />
  );
};

export default MenuAddDialog;
