import React, { FC, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { LinkOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import styles from './ProjectChoose.module.scss';
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { setCurrentProject } from '../../../store/slices/layout/layoutSlice'
import { ProjectItem } from '../../../store/slices/layout/types'
import { logout } from '../../../store/generalSlice'

interface ProjectChoose {
  bordered?: boolean;
}

const ProjectChoose: FC<ProjectChoose> = ({ bordered }) => {
  const { currentProject, projectList } = useAppSelector((state) => state.layout);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const currentProjectId = localStorage.getItem('currentProjectId');

  const onSelectHandler = (item: ProjectItem) => {
    localStorage.setItem('currentProjectId', item.value);
    dispatch(setCurrentProject(item));
    if (location.pathname !== '/login') {
      // у каждого проекта свой токен (?)
      // window.location.reload();
      dispatch(logout());
    }
  };

  useEffect(() => {
    const projectFromLocalStorage = projectList.find((project => project.value === currentProjectId));
    dispatch(setCurrentProject(projectFromLocalStorage));
  }, [currentProjectId, dispatch, projectList]);

  return (
    <div>
      <LinkOutlined rev={''} />
      <Select
        value={currentProject}
        options={projectList}
        placeholder={'Не выбрано'}
        onSelect={(_, {
          label,
          value
        }) =>
          onSelectHandler({
            label,
            value
          })
        }
        className={styles.customSelect}
        bordered={bordered || false}
      />
    </div>
  );
};

export default ProjectChoose;
