import React from 'react';
import { useSelector } from 'react-redux';
import '../assets/css/index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import { ru } from 'date-fns/locale';
import { RootState, useAppDispatch } from '../store/store';
import { fetchUserInfo } from '../store/generalSlice';
import Dashboard from './Dashboard';
import { Login } from './login/Login';
import PagesLayout from './pages/PagesLayout';
import PageForm from './pages/PageForm';
import MenuItemLayout from './menu/MenuItemLayout';
import MenuItemForm from './menu/MenuItemForm';
import NewsPageLayout from './news/NewsPageLayout';
import NewsPageForm from './news/NewsPageForm';
import SettingsForm from './settings/SettingsForm';
import SettingsLayout from './settings/SettingsLayout';
import { Loader } from './common/Loader/Loader';
import { MdEdit } from './common/MdEdit/MdEdit';
import { PartnersLayout } from './partners/PartnersLayout';
import { PartnersForm } from './partners/PartnersForm';
import { ReviewsLayout } from './reviews/ReviewsLayout';
import { ReviewsForm } from './reviews/ReviewsForm';
import { BlocksLayout } from './blocks/BlocksLayout';
import { BlocksForm } from './blocks/BlocksForm';
import Layout from './layout/index';

setDefaultOptions({ locale: ru });

const App = () => {

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Layout />}>
          <Route index element={<Dashboard />} />
          <Route path="edit/news-page/:id" element={<MdEdit />} />
          <Route path="edit/page/:id" element={<MdEdit />} />

          <Route path="pages" element={<PagesLayout />}>
            <Route path=":id" element={<PageForm />} />
          </Route>
          <Route path="news-page" element={<NewsPageLayout />}>
            <Route path=":id" element={<NewsPageForm />} />
          </Route>
          <Route path="menu" element={<MenuItemLayout />}>
            <Route path=":id" element={<MenuItemForm />} />
          </Route>
          <Route path="partners" element={<PartnersLayout />}>
            <Route path=":id" element={<PartnersForm />} />
          </Route>
          <Route path="reviews" element={<ReviewsLayout />}>
            <Route path=":id" element={<ReviewsForm />} />
          </Route>
          <Route path="blocks" element={<BlocksLayout />}>
            <Route path=":id" element={<BlocksForm />} />
          </Route>
          <Route path="settings" element={<SettingsLayout />}>
            <Route path=":id" element={<SettingsForm />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
