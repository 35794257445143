import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Header, Sider } from '../index';
import styles from './layout.module.css';
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { fetchUserInfo } from '../../../store/generalSlice'
import { Loader } from '../../common/Loader/Loader'

const Layout = () => {
  const { token, initial } = useAppSelector((state) => state.general);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [token, navigate])

  if (initial) {
    dispatch(fetchUserInfo(token));
    return <Loader general />;
  }

  return (
    <>
      <Header />
      <main className={styles.main}>
        <Sider />
        <div className={styles.content}>
          <Outlet />
        </div>
      </main>
    </>
  );
};

export default Layout;
