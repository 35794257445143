import { Button, Table } from 'antd'
import * as React from 'react'
import { fileApi } from '../../../api/apis'
import { useAppDispatch } from '../../../store/store'
import { getBlock } from '../../../store/slices/block/blocksActions'
import { FC } from 'react'
import { BlockDto } from '../../../generated/backend'

interface FormFilesTable {
  id: number;
  data: BlockDto;
}

const FormFilesTable: FC<FormFilesTable> = ({ id, data }) => {
  const dispatch = useAppDispatch();

  const onRemove = async (fileId: string) => {
    await fileApi.apiFileIdDelete(fileId);
    await dispatch(getBlock(id));
  };

  const columns = [
    { key: 'key', title: '№', dataIndex: 'key' },
    { key: 'filename', title: 'File Name', dataIndex: 'name' },
    {
      key: 'actions',
      title: 'Actions',
      render: (_, record) => (
        <Button type={'text'} onClick={() => onRemove(record.id)}>
          Удалить
        </Button>
      )
    }
  ];

  return (
    <Table
      columns={columns}
      dataSource={data?.files?.map((item, index) => ({
        ...item,
        key: index + 1
      }))}
      size={'small'}
      pagination={false}
    />
  )
};

export default FormFilesTable;
