import { Col, Form, Select } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react'
import { menuApi, pageApi } from '../../../api/apis'
import { MenuItemDto, PageDto } from '../../../generated/backend'
import { DefaultOptionType } from 'rc-select/lib/Select';

interface ParentSelector<T> {
  entityType: T;
  value: string | number;
  onSelectChange: (_newValue: { [key: string]: T extends 'menu' ? MenuItemDto : PageDto }) => void;
  labelName: string;
}

type OptionType = (PageDto | MenuItemDto) & DefaultOptionType;

const SelectorWithSearch: FC<ParentSelector<'page' | 'menu'>> = (props) => {
  const { value, onSelectChange, entityType, labelName, } = props;
  const [parents, setParents] = useState<PageDto[] | MenuItemDto[]>([]);
  const [loading, setLoading] = useState(true);

  const getParents = useCallback(async () => {
    setLoading(true);
    if (entityType === 'page') {
      const re = await pageApi.apiPageGet();
      setParents(re.data);
    }
    if (entityType === 'menu') {
      const re = await menuApi.apiMenuItemGet();
      setParents(re.data);
    }
    setLoading(false);
  }, [entityType]);

  useEffect(() => {
    (async () => {
      await getParents();
    })()
  }, [getParents]);

  const getOptions = () => {
    return parents.map((item: PageDto | MenuItemDto): OptionType => ({
      ...item,
      label: item.name,
      value: item.id,
    }))
  };

  const filterOptions = (input: string, option: OptionType) => {
    return (option.name.toLowerCase()).includes(input.toLowerCase());
  };

  const filterSort = (a: OptionType, b: OptionType) => {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  };

  const findOptionValue = (value: number): MenuItemDto | PageDto => {
    // @ts-expect-error ошибка из-за объединенного типа, надо поднимать версию TS (текущая 4.7.4)
    return parents.find((item: MenuItemDto | PageDto) => item.id === value);
  };

  const onSelect = (value: number) => {
    const selected = findOptionValue(value);
    onSelectChange({ [labelName]: selected });
  };

  return (
    <Col span={24}>
      <Form.Item label={labelName}>
        <Select
          options={getOptions()}
          placeholder={'Не выбрано'}
          filterOption={filterOptions}
          filterSort={filterSort}
          value={value}
          style={{ width: '100%' }}
          onSelect={onSelect}
          onFocus={getParents}
          loading={loading}
          showSearch
        />
      </Form.Item>
    </Col>
  );
};

export default SelectorWithSearch;
