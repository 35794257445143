import { createSlice } from '@reduxjs/toolkit'
import { getAllBlocks, getBlock } from './blocksActions'
import { BlocksState } from './types'

const initialState: BlocksState = {
  selected: {
    block: {},
    loading: true
  },
  tree: {
    loading: true,
    blocks: [],
  }
};

const blockSlice = createSlice({
  name: 'blocks',
  initialState,
  reducers: {
    clearSelected: (state) => {
      state.selected.block = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllBlocks.pending, (state) => {
      state.tree.loading = true;
    });
    builder.addCase(getAllBlocks.fulfilled, (state, action) => {
      state.tree.blocks = action.payload;
      state.tree.loading = false;
    });

    builder.addCase(getBlock.pending, (state) => {
      state.selected.loading = true;
    });
    builder.addCase(getBlock.fulfilled, (state, action) => {
      state.selected.block = action.payload;
      state.selected.loading = false;
    });
  },
});

export default blockSlice.reducer;

export const { clearSelected } = blockSlice.actions;
