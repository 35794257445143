import { createAsyncThunk } from '@reduxjs/toolkit';
import { blockApi, fileApi, pageApi } from '../../../api/apis';
import { PageDto } from '../../../generated/backend'

export const getAllPages = createAsyncThunk(
  'pages/getAll',
  async (expandedNodes: number[] = [], { rejectWithValue }) => {
    try {
      const nodes = expandedNodes.map(node => node.toString());
      const res = await pageApi.apiTreePageGet(nodes);
      // console.log(res.data)
      return res.data;
    } catch (e) {
      rejectWithValue(e.message);
    }
  }
);

export const getPageById = createAsyncThunk(
  'pages/getById',
  async (id: number, { rejectWithValue })=> {
    try {
      const res = await pageApi.apiPageIdGet(id.toString());
      return res.data;
    } catch (e) {
      rejectWithValue(e.message);
    }
  }
);

export const updatePage = createAsyncThunk(
  'page/submit',
  async (page: PageDto, { rejectWithValue }) => {
    try {
      const res = await pageApi.apiPagePut(page);
      return res.data;
    } catch (e) {
      rejectWithValue(e.message);
    }
  }
);

export const deletePage = createAsyncThunk(
  'pages/deletePage',
  async (pageId: number, { rejectWithValue }) => {
    try {
      const page = await pageApi.apiPageIdGet(pageId.toString());
      // todo проверить удаление, возможно придётся разделить. Возможно проверить локально после разрешения с s3
      await Promise.all([...page.data.blocks.map(async (item) => {
        blockApi.apiBlockIdDelete(`${item.id}`);
      }), ...page.data.files.map(async (item) => {
        fileApi.apiFileIdDelete(`${item.id}`);
      })]);
      await pageApi.apiPageIdDelete(pageId.toString());
    } catch (e) {
      rejectWithValue(e.message);
    }
  }
);
