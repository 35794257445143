import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import React, { FC } from 'react'

interface AddButton {
  onClick: () => void;
}

const AddButton: FC<AddButton> = ({ onClick }) => (
  <Button
    onClick={onClick}
    icon={<PlusOutlined rev="" />}
    type={'text'}
  >
      Добавить
  </Button>
);

export default AddButton;
