import React, { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Form, Input, message, Row } from 'antd';
import ButtonGroup from '../common/forForm/ButtonGroup'
import FormInput from '../common/forForm/FormInput'
import { SettingDto } from '../../generated/backend'
import { useAppDispatch, useAppSelector } from '../../store/store'
import {
  getAllSettings,
  getSetting,
  updateSetting,
} from '../../store/slices/settings/settingsActions'

const SettingsForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setting } = useAppSelector((state) => state.settings.selected);
  const [data, setData] = useState<SettingDto>({});
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSetting(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (setting.name) {
      setData(setting);
    }
  }, [dispatch, setting])

  const onSubmit = () => {
    dispatch(updateSetting(data));
    dispatch(getAllSettings());
    message.success('Сохранено!');
  };

  const onCloseHandler = () => {
    navigate('/settings');
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [e.target.name]: e.target.value || '',
    })
  };

  return (
    <div>
      <Form labelCol={{ span: 2, offset: 0 }} labelAlign={'left'}>
        <ButtonGroup onSubmit={onSubmit} onClose={onCloseHandler} />
        <Row gutter={16}>

          <Col span={24}>
            <Form.Item label={'id'}>
              <Input name={'id'} value={id} disabled />
            </Form.Item>
          </Col>

          <FormInput fieldName={'name'} value={data.name} onChange={onInputChange} />

          <FormInput fieldName={'value'} value={data.value} onChange={onInputChange} />

        </Row>
      </Form>
    </div>
  );
};

export default SettingsForm;
