import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom'
import styles from './sider.module.css';
import { MenuItem } from '../../../store/slices/layout/types'

interface SiderItemMenu {
  item: MenuItem;
  activeItem: string;
}

const SiderItemMenu: FC<SiderItemMenu> = (props) => {
  const { item, activeItem } = props;
  const navigate = useNavigate();

  return (
    <div
      style={{ backgroundColor: item.key === activeItem ? '#38649f' : '' }}
      className={styles.item}
      onClick={() => {
        navigate(item.path);
      }}
    >
      {/* {itemNav.icon} */}
      <div className={styles.itemTitle}>{item.label}</div>
    </div>
  );
};

export default SiderItemMenu;
