import { useAppDispatch, useAppSelector } from '../../store/store'
import { Tree } from 'antd'
import React, { useEffect } from 'react'
import { SettingDto } from '../../generated/backend'
import TreeItem from '../common/forTree/TreeItem'
import { deleteSettingById, getAllSettings } from '../../store/slices/settings/settingsActions'
import { Loader } from '../common/Loader/Loader'
import { useNavigate } from 'react-router-dom'

const SettingsTree = () => {
  const dispatch = useAppDispatch();
  const { settings, loading } = useAppSelector((state) => state.settings.tree);
  const selectedSetting = useAppSelector(state => state.settings.selected.setting);
  const navigate = useNavigate();


  const onSettingDelete = async (id: string) => {
    await dispatch(deleteSettingById(id));
    await dispatch(getAllSettings());
    navigate('/settings');
  };

  const transformItemToNode = (setting: SettingDto) => {
    const settingName: string = setting.name + ' (' + setting.id + ')' + '[' + setting.value + ']';
    return {
      ...setting,
      key: setting.id,
      title: <TreeItem itemName={settingName} onDelete={() => onSettingDelete(setting.id)} />
    };
  };

  const onItemSelect = (id: string) => {
    navigate(`/settings/${id}`);
  };

  useEffect(() => {
    dispatch(getAllSettings());
  }, [dispatch]);

  if (!settings.length && loading) {
    return <Loader />
  }

  return (
    <Tree
      selectedKeys={[selectedSetting.id] || []}
      treeData={settings.map(transformItemToNode) || []}
      onSelect={(_, info) => {
        onItemSelect(info.node.id);
      }}
    />
  );
};

export default SettingsTree;
