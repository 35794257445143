import AddDialog from '../common/AddDialog/AddDialog'
import React, { FC } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { pageApi } from '../../api/apis'
import { PageDto } from '../../generated/backend'
import { useNavigate } from 'react-router-dom'
import { getAllPages } from '../../store/slices/pages/pagesActions'

interface PageAddDialog {
  isOpen: boolean;
  onClose: () => void;
  updateExpandedKeys: (_keys: number[]) => void;
  expandedKeys: number[];
}

const PageAddDialog: FC<PageAddDialog> = ({ onClose, isOpen, updateExpandedKeys, expandedKeys }) => {
  const { selected } = useAppSelector((state) => state.pages);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onSave = async (page: Pick<PageDto, 'name' | 'parent'>) => {
    const createdPage = await pageApi.apiPagePost(page)
    if (selected.page) {
      updateExpandedKeys([...expandedKeys, selected.page.id]);
    }
    dispatch(getAllPages(expandedKeys));
    navigate(`/pages/${createdPage.data.id}`);
    onClose();
  };

  return (
    <AddDialog
      isOpen={isOpen}
      parent={selected.page}
      onClose={onClose}
      onSave={onSave}
    />
  )
}

export default PageAddDialog;
