import { createAsyncThunk } from '@reduxjs/toolkit'
import { partnerApi } from '../../../api/apis'

export const getAllPartners = createAsyncThunk(
  'partners/getAll',
  async (_, { rejectWithValue }) => {
    try {
      const res = await partnerApi.apiPartnerGet();
      return res.data;
    } catch (e) {
      rejectWithValue(e.message);
    }
  },
);

export const getPartner = createAsyncThunk(
  'partners/getById',
  async (id: number, { rejectWithValue }) => {
    try {
      const res = await partnerApi.apiPartnerIdGet(id.toString());
      return res.data;
    } catch (e) {
      rejectWithValue(e.message);
    }
  },
);
