import { Button, Checkbox, message, Table } from 'antd';
import * as React from 'react';
import { FC } from 'react';
import {
  FileDto,
} from '../../../../generated/backend';
import { ColumnsType } from 'antd/es/table';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { fileApi, newsPageApi, pageApi } from '../../../../api/apis';

interface FileTable {
  fetchPage: () => Promise<void>;
  files: FileDto[];
  isPage: boolean;
  pageId: string;
}

const FileTable: FC<FileTable> = ({ fetchPage, files, isPage, pageId }) => {

  const onRemove = async (fileId: string) => {
    await fileApi.apiFileIdDelete(fileId);
    await fetchPage();
  };

  const updateValue = (e: CheckboxChangeEvent, file: FileDto): FileDto => {
    if (e.target.name === 'isMeta' && e.target.checked) {
      return {
        ...file,
        isMeta: true,
        isHeader: false,

      };
    }
    if (e.target.name === 'isHeader' && e.target.checked) {
      return {
        ...file,
        isMeta: false,
        isHeader: true,
      };
    }
    return {
      ...file,
      [e.target.name]: e.target.checked,
    }
  };

  const flagIsNotUnique = (fieldName: 'isMeta' | 'isHeader') => {
    const counter = files.filter(file => file[fieldName]).length;
    return counter > 0;
  };

  const updateFile = async (file: FileDto) => {
    if (isPage) {
      await pageApi.apiPageIdFilesPut(pageId, file);
    } else {
      await newsPageApi.apiNewsPageIdFilesPut(pageId, file);
    }
    await fetchPage();
  };

  const handleChange = async (e: CheckboxChangeEvent, file: FileDto) => {
    const valueForUpdate = updateValue(e, file);

    if (e.target.name === 'isMeta' || e.target.name === 'isHeader') {

      const isNotUnique = flagIsNotUnique(e.target.name);
      if (isNotUnique && e.target.checked) {
        message.error('Выберите только один файл');
      } else {
        await updateFile(valueForUpdate);
      }

    }
  };

  const columns: ColumnsType = [
    { key: 'key', title: '№', dataIndex: 'key', render: (value, record, index) => ++index },
    { key: 'filename', title: 'File Name', dataIndex: 'name' },
    {
      key: 'isMeta', title: 'Is meta', dataIndex: 'isMeta',
      render: (value, record: FileDto) => (
        <Checkbox name={'isMeta'} checked={value} onChange={async (e) => {
          await handleChange(e, record);
        }}
        />
      ),
    },
    {
      key: 'isHeader', title: 'Is header', dataIndex: 'isHeader',
      render: (value, record: FileDto) => (
        <Checkbox name={'isHeader'} checked={value} onChange={async (e) => {
          await handleChange(e, record);
        }}
        />
      ),
    },
    {
      key: 'actions',
      title: 'Actions',
      render: (_, record: FileDto) => (
        <Button type={'text'} onClick={() => onRemove(record.id)}>
          Удалить
        </Button>
      )
    }
  ];

  return (
    <Table
      columns={columns}
      dataSource={files}
      size={'small'}
    />
  );
};

export default FileTable;
