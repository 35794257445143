import React, { FC } from 'react'
import { useAppSelector } from '../../../store/store'
import { Empty, List } from 'antd'
import { FileDto } from '../../../generated/backend'

interface ImagesTab {
}

const ImagesTab: FC<ImagesTab> = () => {
  const { files } = useAppSelector((state) => state.pages.selected.page);

  const renderItem = (file: FileDto) => (
    <List.Item key={file.id}>
      {file.name}
    </List.Item>
  );

  if (files.length === 0) {
    return <Empty />
  }

  return (
    <List
      dataSource={files}
      renderItem={renderItem}
    />
  )
}

export default ImagesTab
