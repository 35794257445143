import React from 'react';
import styles from './header.module.css';
import Logo from '../../../assets/img/logo-white-min.svg';
import { Breadcrumbs } from '../index';
import { useNavigate } from 'react-router-dom';
import ProjectChoose from '../../common/ProjectChoose/ProjectChoose';
import { Avatar, Space } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { useAppDispatch } from '../../../store/store';
import { logout } from '../../../store/generalSlice';

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  return (
    <header className={styles.header}>
      <div className={styles.logo} onClick={() => navigate('/')}>
        <Logo />
        <div className={styles.logoTitle}>CMS</div>
      </div>
      <div className={styles.contentHeader}>
        <ul className={styles.breadCrumbs}>
          <Breadcrumbs />
        </ul>
        <div className={styles.text}>
          <Space align={'center'} >
            <ProjectChoose />
            <Avatar style={{ background: 'blue', color: 'white' }}>
              rbi
            </Avatar>
            <LogoutOutlined
              style={{ fontSize: '28px' }}
              rev={""}
              onClick={() => {
                dispatch(logout());
              }}
            />
          </Space>
        </div>
      </div>
    </header>
  );
};

export default Header;
