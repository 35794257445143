import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Col, Row, } from 'antd';
import PagesTree from './pagesTree/PagesTree';
import AddButton from '../common/forTree/AddButton'
import PageAddDialog from './PageAddDialog'
import { useLocalStorage } from 'usehooks-ts'

const PagesLayout = () => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [expandedKeys, setExpandedKeys] = useLocalStorage<number[]>('pages', []);

  const onAddHandler = () => {
    setDialogIsOpen(true);
  };

  const onCloseDialog = () => {
    setDialogIsOpen(false);
  };

  return (
    <>
      <Row justify="space-around">
        <Col span={6}>
          <AddButton onClick={onAddHandler} />
          <PagesTree
            expandedKeys={expandedKeys}
            updateExpandedKeys={setExpandedKeys}
          />
        </Col>
        <Col span={17}>
          <Outlet />
        </Col>
      </Row>
      {dialogIsOpen && <PageAddDialog isOpen={dialogIsOpen} onClose={onCloseDialog}
        expandedKeys={expandedKeys}
        updateExpandedKeys={setExpandedKeys} />}
    </>
  );
};

export default PagesLayout;
