import React, { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Col, Row, } from 'antd'
import AddDialog from '../common/AddDialog/AddDialog'
import { newsPageApi } from '../../api/apis'
import NewsPageTree from './newsPageTree/NewsPageTree'
import AddButton from '../common/forTree/AddButton'
import { NewsPageDto } from '../../generated/backend'
import { useAppDispatch } from '../../store/store'
import { getAllNewsPages } from '../../store/slices/newsPages/newsPagesActions'

const NewsPageLayout = () => {
  const layoutKey = 'news-page';
  const [showDialog, setShowDialog] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onAddHandler = async () => {
    setShowDialog(true)
  }

  const onCloseDialog = () => {
    setShowDialog(false)
  }

  const onSaveDialog = async (newsPage: NewsPageDto) => {
    const createdEntity = await newsPageApi.apiNewsPagePost(newsPage)
    navigate(`/${layoutKey}/${createdEntity.data.id}`);
    await dispatch(getAllNewsPages());
    setShowDialog(false);
  }

  return (
    <>
      <Row justify={'space-around'}>
        <Col span={10}>
          <AddButton onClick={onAddHandler} />
          <NewsPageTree />
        </Col>
        <Col span={13}>
          <Outlet />
        </Col>
      </Row>
      <AddDialog
        isOpen={showDialog}
        onClose={onCloseDialog}
        onSave={onSaveDialog}
      />
    </>
  )
}

export default NewsPageLayout
