import { createSlice } from '@reduxjs/toolkit'
import { getAllNewsPages, getNewsPageById } from './newsPagesActions'
import { NewsPagesState } from './types'

const initialState: NewsPagesState = {
  selected: {
    loading: true,
    page: undefined,
  },
  tree: {
    loading: true,
    pages: []
  }
}

const newsPagesSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllNewsPages.pending, (state) => {
      state.tree.loading = true;
    });
    builder.addCase(getAllNewsPages.fulfilled, (state, action) => {
      state.tree.pages = action.payload;
      state.tree.loading = false;
    });
    builder.addCase(getAllNewsPages.rejected, () => {});

    builder.addCase(getNewsPageById.pending, (state) => {
      state.selected.loading = true;
    });
    builder.addCase(getNewsPageById.fulfilled, (state, action) => {
      state.selected.page = action.payload;
      state.selected.loading = false;
    });
    builder.addCase(getNewsPageById.rejected, (state) => {
      state.selected.loading = false;
    })
  },
});

export default newsPagesSlice.reducer;

export const {} = newsPagesSlice.actions;
