import { createAsyncThunk } from '@reduxjs/toolkit'
import { reviewApi } from '../../../api/apis'

export const getAllReviews = createAsyncThunk(
  'reviews/getAll',
  async (_, { rejectWithValue }) => {
    try {
      const res = await reviewApi.apiReviewGet();
      return res.data;
    } catch (e) {
      rejectWithValue(e.message);
    }
  },
);

export const getReview = createAsyncThunk(
  'reviews/getById',
  async (id: number, { rejectWithValue }) => {
    try {
      const res = await reviewApi.apiReviewIdGet(id.toString());
      return res.data;
    } catch (e) {
      rejectWithValue(e.message);
    }
  },
);
