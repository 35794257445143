import React, { FC, useEffect, useState } from 'react';
import { SectionDto } from '../../../generated/backend';
import { Button, Col, Form, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { sectionsApi } from '../../../api/apis';
import CreateSectionModal from './CreateSectionModal';
import { DefaultOptionType } from 'rc-select/lib/Select';

interface SectionSelector {
  values: SectionDto[];
  onChange: (_values: { sections: SectionDto[] }) => void;
}

type OptionType = SectionDto & DefaultOptionType;

const SectionSelector: FC<SectionSelector> = (props) => {
  const { values, onChange } = props;
  const [modalState, setModalState] = useState<boolean>(false);
  const [sections, setSections] = useState<SectionDto[]>([]);
  const [selectorLoad, setSelectorLoad] = useState<boolean>(true);

  const getSections = async () => {
    setSelectorLoad(true);
    const res = await sectionsApi.apiSectionGet();
    setSections(res.data);
    setSelectorLoad(false);
  };

  useEffect(() => {
    (async () => {
      await getSections();
    })()
  }, []);

  const getValuesId = (): number[] => {
    if (!values) {
      return [];
    }
    return values.map((item: SectionDto): number => item.id);
  };

  const getOptions = () => {
    return sections.map((section: SectionDto): OptionType => ({
      ...section,
      value: section.id,
      label: section.title,
    }))
  };

  const getSelectedSections = (values: number[]) => {
    const selectedValues: SectionDto[] = [];
    values.forEach((value) => {
      const sectionInValues = sections.find(section => value === section.id);
      selectedValues.push(sectionInValues);
    });
    return selectedValues;
  };

  const handleChange = (values: number[]) => {
    const selectedSections = {
      sections: getSelectedSections(values),
    };
    onChange(selectedSections);
  };

  const renderDropdown = (menu: React.ReactElement): React.ReactElement => {
    return (
      <>
        {menu}
        <Button type="text"
          icon={<PlusOutlined rev={''}/>}
          onClick={() => setModalState(true)} style={{ width: '100%' }}
        >
          Добавить раздел
        </Button>
      </>
    );
  }

  return (
    <>
      <Col span={24}>
        <Form.Item label={'section'}>
          <Select
            options={getOptions()}
            dropdownRender={renderDropdown}
            onClick={getSections}
            onChange={handleChange}
            value={getValuesId()}
            loading={selectorLoad}
            mode="multiple"
            allowClear
          />
        </Form.Item>
      </Col>

      <CreateSectionModal open={modalState} onClose={() => setModalState(false)} />
    </>
  );
};

export default SectionSelector;
