import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import generalReducer from './generalSlice';
import pagesReducer from './slices/pages/pagesSlice';
import layoutReducer from './slices/layout/layoutSlice';
import newsPagesReducer from './slices/newsPages/newsPagesSlice';
import menuReducer from './slices/menu/menuSlice';
import partnersReducer from './slices/partners/partnersSlice'
import reviewsReducer from './slices/reviews/reviewsSlice'
import settingsReducer from './slices/settings/settingsSlice'
import blocksReducer from './slices/block/blockSlice'

export const store = configureStore({
  reducer: {
    general: generalReducer,
    pages: pagesReducer,
    layout: layoutReducer,
    news: newsPagesReducer,
    menu: menuReducer,
    partners: partnersReducer,
    reviews: reviewsReducer,
    settings: settingsReducer,
    blocks: blocksReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
