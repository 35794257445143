import * as React from 'react';
import { FC, useState } from 'react';
import { Col, Row, Space } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';
import { BlockDto } from '../../generated/backend';
import AddDialog from '../common/AddDialog/AddDialog';
import { blockApi } from '../../api/apis'
import AddButton from '../common/forTree/AddButton'
import BlockTree from './BlockTree'
import { useAppDispatch } from '../../store/store'
import { getAllBlocks } from '../../store/slices/block/blocksActions'

export const BlocksLayout: FC = () => {
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const onAddHandler = () => {
    setShowDialog(true);
  };

  const onCloseDialog = () => {
    setShowDialog(false);
  };

  const onSaveDialog = async (block: BlockDto) => {
    const createdBlock = await blockApi.apiBlockPost(block);
    await dispatch(getAllBlocks());
    navigate(`/blocks/${createdBlock.data.id}`);
    setShowDialog(false);
  };

  return (
    <div>
      <Row justify={'space-around'}>
        <Col span={6}>
          <Space>
            <AddButton onClick={onAddHandler} />
          </Space>
          <BlockTree />
        </Col>
        <Col span={17}>
          <Outlet />
        </Col>
      </Row>
      {showDialog && (
        <AddDialog
          isOpen={showDialog}
          onClose={onCloseDialog}
          onSave={onSaveDialog}
        />
      )}
    </div>
  );
};
