import * as React from 'react';
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { Form, message, Row } from 'antd';
import { Loader } from '../common/Loader/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { PartnerDto } from '../../generated/backend';
import { partnerApi } from '../../api/apis';
import { useAppDispatch, useAppSelector } from '../../store/store'
import { getAllPartners, getPartner } from '../../store/slices/partners/partnersActions'
import ButtonGroup from '../common/forForm/ButtonGroup'
import FormInput from '../common/forForm/FormInput'
import PartnerFilesTable from './partnerFilesTable/PartnerFilesTable'
import PartnerFilesDragger from './partnerFilesDragger/PartnerFilesDragger'
import { clearSelected } from '../../store/slices/partners/partnersSlice'

export const PartnersForm: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState<PartnerDto>(undefined);
  const dispatch = useAppDispatch();
  const { partner, loading } = useAppSelector(state => state.partners.selected)

  useEffect(() => {
    (async () => {
      await dispatch(getPartner(Number(id)));
    })();
    return () => {
      dispatch(clearSelected());
    }
  }, [dispatch, id]);

  useEffect(() => {
    setData(partner);
  }, [partner]);

  const onSubmit = async () => {
    await partnerApi.apiPartnerPut(data);

    message.success('Сохранено!');
    await dispatch(getAllPartners());
  };

  const onCloseHandler = () => {
    navigate('/partners');
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [e.target.name]: e.target.value || '',
    })
  };

  if (!data || loading) {
    return <Loader />;
  }

  return (
    <div>
      <Form labelCol={{ span: 2, offset: 0 }} labelAlign={'left'}>
        <ButtonGroup onSubmit={onSubmit} onClose={onCloseHandler} />
        <Row>
          <FormInput fieldName={'name'} value={data?.name} onChange={onInputChange} />
        </Row>
      </Form>
      <PartnerFilesTable id={id} data={data} />
      <PartnerFilesDragger id={id} />
    </div>
  );
};
