import { createAsyncThunk } from '@reduxjs/toolkit'
import { settingApi } from '../../../api/apis'
import { SettingDto } from '../../../generated/backend'

export const getAllSettings = createAsyncThunk(
  'settings/getAll',
  async (_, { rejectWithValue }) => {
    try {
      const res = await settingApi.apiSettingGet();
      return res.data;
    } catch (e) {
      rejectWithValue(e.message);
    }
  }
);

export const getSetting = createAsyncThunk(
  'setting/getById',
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await settingApi.apiSettingIdGet(id);
      return res.data;
    } catch (e) {
      rejectWithValue(e.message);
    }
  }
);

export const updateSetting = createAsyncThunk(
  'settings/update',
  async (setting: SettingDto, { rejectWithValue }) => {
    try {
      const res = await settingApi.apiSettingPut(setting);
      return res.data;
    } catch (e) {
      rejectWithValue(e.message);
    }
  }
);

export const deleteSettingById = createAsyncThunk(
  'settings/delete',
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await settingApi.apiSettingIdDelete(id);
      return res.data;
    } catch (e) {
      rejectWithValue(e.message);
    }
  },
);
