import { MenuTypeDto, StatusDto } from '../../../generated/backend'
import { Col, Form, Select } from 'antd'
import React, { FC } from 'react'

interface SimpleSelector {
  options: StatusDto[] | MenuTypeDto[];
  value: string;
  onChange: (_option: { [key: string]: StatusDto | MenuTypeDto }) => void;
  label: string;
}

const SimpleSelector: FC<SimpleSelector> = (props) => {
  const { options, onChange, value, label } = props;

  const onValueChange = (_: never, option: StatusDto | MenuTypeDto) => {
    onChange({
      [label]: {
        value: option.value,
        label: option.label,
      }
    })
  };

  return (
    <Col span={24}>
      <Form.Item label={label}>
        <Select
          options={options}
          value={value}
          onChange={onValueChange}
          style={{ width: '100%' }}
        />
      </Form.Item>
    </Col>
  );
};

export default SimpleSelector;
