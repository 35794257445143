import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getPageById } from '../pages/pagesActions'
import { getNewsPageById } from '../newsPages/newsPagesActions'
import { getMenuItem } from '../menu/menuActions'
import { getPartner } from '../partners/partnersActions'
import { getReview } from '../reviews/reviewsActions'
import { getSetting } from '../settings/settingsActions'
import { initialState } from './initialState'
import { ProjectItem } from './types'
import { getBlock } from '../block/blocksActions'

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setActiveItem: (state, action: PayloadAction<string>) => {
      state.siderMenu.activeItem = action.payload;
    },
    setCurrentPageName: (state, action: PayloadAction<string>) => {
      state.currentPage = action.payload;
    },
    setCurrentProject: (state, action: PayloadAction<ProjectItem>) => {
      state.currentProject = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getPageById.pending, (state) => {
      state.currentPage = '';
    });
    builder.addCase(getPageById.fulfilled, (state, action) => {
      state.currentPage = action.payload.name;
    });

    builder.addCase(getNewsPageById.pending, (state) => {
      state.currentPage = '';
    });
    builder.addCase(getNewsPageById.fulfilled, (state, action) => {
      state.currentPage = action.payload.name;
    });

    builder.addCase(getMenuItem.pending, (state) => {
      state.currentPage = '';
    });
    builder.addCase(getMenuItem.fulfilled, (state, action) => {
      state.currentPage = action.payload.name;
    });

    builder.addCase(getPartner.pending, (state) => {
      state.currentPage = '';
    });
    builder.addCase(getPartner.fulfilled, (state, action) => {
      state.currentPage = action.payload.name;
    });

    builder.addCase(getReview.pending, (state) => {
      state.currentPage = '';
    });
    builder.addCase(getReview.fulfilled, (state, action) => {
      state.currentPage = action.payload.name;
    });

    builder.addCase(getSetting.pending, (state) => {
      state.currentPage = '';
    });
    builder.addCase(getSetting.fulfilled, (state, action) => {
      state.currentPage = action.payload.name;
    });

    builder.addCase(getBlock.pending, (state) => {
      state.currentPage = '';
    });
    builder.addCase(getBlock.fulfilled, (state, action) => {
      state.currentPage = action.payload.name;
    });

  }
});

export default layoutSlice.reducer;

export const { setActiveItem, setCurrentPageName, setCurrentProject, } = layoutSlice.actions;
