import { listToTree } from '../../common/forTree/listToTree'
import { Tree } from 'antd'
import React, { FC, useEffect } from 'react'
import { TreeItemDto } from '../../../generated/backend'
import { DataNode } from 'rc-tree/lib/interface'
import TreeItem from '../../common/forTree/TreeItem'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { deletePage, getAllPages } from '../../../store/slices/pages/pagesActions'
import { useNavigate } from 'react-router-dom'
import { setSelectedPage } from '../../../store/slices/pages/pagesSlice'
import { Loader } from '../../common/Loader/Loader'

interface PagesTree {
  updateExpandedKeys: (_keys: number[]) => void;
  expandedKeys: number[];
}

// отображение свертки/развертки узла зависит от isLeaf, до исправления CMS-110 ChildrenCount в PageDto всегда true

const PagesTree: FC<PagesTree> = ({ updateExpandedKeys, expandedKeys }) => {
  const { pages, loading } = useAppSelector((state) => state.pages.tree);
  const selectedPage = useAppSelector(state => state.pages.selected.page);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onPageDelete = async (page: TreeItemDto) => {
    await dispatch(deletePage(page.id));
    navigate('/pages');
    dispatch(setSelectedPage({}))
    await dispatch(getAllPages(expandedKeys));
  }

  const pageToNode = (page: TreeItemDto): DataNode => (
    {
      ...page,
      isLeaf: !page.collapsible,
      key: page.id,
      selectable: !page.selectable,
      title: <TreeItem itemName={page.name} onDelete={async () => await onPageDelete(page)} />,
    }
  );

  const getTreeData = () => {
    const nodes = pages.map(pageToNode);
    return listToTree(nodes);
  };

  const onSelect = (_: never, e: {node: TreeItemDto}) => {
    const { id } = e.node;
    navigate(`/pages/${id}`);
  };

  const onExpand = (expandedKey: number[]) => {
    updateExpandedKeys(expandedKey);
  };

  useEffect(() => {
    dispatch(getAllPages(expandedKeys));
  }, [dispatch, expandedKeys]);

  if (loading && !pages.length) {
    return <Loader />
  }

  return (
    <Tree
      selectedKeys={selectedPage ? [selectedPage.id] : []}
      treeData={getTreeData()}
      expandedKeys={expandedKeys}
      autoExpandParent={false}
      onSelect={onSelect}
      onExpand={onExpand}
      showLine
    />
  );
};

export default PagesTree;
