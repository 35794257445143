import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PagesState } from './types';
import { deletePage, getAllPages, getPageById, updatePage } from './pagesActions'
import { PageDto } from '../../../generated/backend'

const initialState: PagesState = {
  tree: {
    pages: [],
    expanded: [],
    loading: true,
  },
  selected: {
    page: null,
    loading: true,
  }
}

const pagesSlice = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    setSelectedPage: (state, action: PayloadAction<Partial<PageDto>>) => {
      state.selected.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPages.pending, (state) => {
      state.tree.loading = true;
    });
    builder.addCase(getAllPages.fulfilled, (state, action) => {
      const { items, expanded } = action.payload;
      state.tree.pages = items;
      state.tree.expanded = expanded;
      state.tree.loading = false;
    });
    builder.addCase(getAllPages.rejected, (state) => {
      state.tree.loading = false;
    });

    builder.addCase(getPageById.pending, (state) => {
      state.selected.loading = true;
    });
    builder.addCase(getPageById.fulfilled, (state, action) => {
      state.selected.loading = false;
      state.selected.page = action.payload;
    });
    builder.addCase(getPageById.rejected, (state) => {
      state.selected.loading = false;
    });

    builder.addCase(updatePage.pending, (state) => {
      state.selected.loading = true;
    });
    builder.addCase(updatePage.fulfilled, (state, action) => {
      // todo раскомментировать после выполнения CMS-111 Update PageDto
      // state.selected.loading = false;
      // state.selected.page = action.payload;
    });

    builder.addCase(deletePage.pending, (state) => {
      state.tree.loading = true;
    });
    builder.addCase(deletePage.fulfilled, (state) => {
      state.tree.loading = false;
    });
    builder.addCase(deletePage.rejected, (state) => {
      state.tree.loading = false;
    })
  },
});

export default pagesSlice.reducer;

export const { setSelectedPage } = pagesSlice.actions;
