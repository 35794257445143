import * as React from 'react'
import { FC, useState } from 'react'
import { Col, Row, Space } from 'antd'
import { Outlet, useNavigate } from 'react-router-dom'
import { ReviewDto } from '../../generated/backend'
import AddDialog from '../common/AddDialog/AddDialog'
import { reviewApi } from '../../api/apis'
import { useAppDispatch } from '../../store/store'
import { getAllReviews } from '../../store/slices/reviews/reviewsActions'
import ReviewsTree from './ReviewsTree'
import AddButton from '../common/forTree/AddButton'

export const ReviewsLayout: FC = () => {
  const navigate = useNavigate()
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const dispatch = useAppDispatch();

  const onAddHandler = () => {
    setShowDialog(true)
  }

  const onCloseDialog = () => {
    setShowDialog(false)
  }

  const onSaveDialog = async (review: ReviewDto) => {
    const createdReview = await reviewApi.apiReviewPost(review)

    navigate(`/reviews/${createdReview.data.id}`)
    setShowDialog(false)
    await dispatch(getAllReviews());
  }

  return (
    <div>
      <Row justify={'space-around'}>
        <Col span={6}>
          <Space>
            <AddButton onClick={onAddHandler} />
          </Space>
          <ReviewsTree />
        </Col>
        <Col span={17}>
          <Outlet />
        </Col>
      </Row>
      {showDialog && (
        <AddDialog
          isOpen={showDialog}
          onClose={onCloseDialog}
          onSave={onSaveDialog}
        />
      )}
    </div>
  )
}
