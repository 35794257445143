import Dragger from 'antd/es/upload/Dragger'
import React, { FC } from 'react'
import { message, UploadProps } from 'antd'
import axios from 'axios'
import { useAppDispatch } from '../../../store/store'
import { getAllNewsPages } from '../../../store/slices/newsPages/newsPagesActions'

interface NewsPageFormDragger {
  id: number;
}

const NewsPageFormDragger: FC<NewsPageFormDragger> = ({ id }) => {
  const dispatch = useAppDispatch()

  const uploadProps: UploadProps = {
    multiple: true,
    type: 'drag',
    progress: {
      format: percent => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    customRequest: async info => {
      const {
        onSuccess,
        file,
        onProgress,
      } = info

      if (typeof file === 'string') return

      const fmData = new FormData()
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          Size: file.size,
        },
        onUploadProgress: event => {
          onProgress({ percent: (event.loaded / event.total) * 100 })
        },
      }

      fmData.append('image', file)

      try {
        await axios.post(
          `/api/file/upload?entityId=${id}&entityType=NewsPage`,
          fmData,
          config,
        )
        onSuccess('Ok')

        dispatch(getAllNewsPages())

        message.success('Файл успешно загружен')
      } catch (err) {
        message.error('Что-то пошло не так')
      }
    },
  }

  return (
    <section
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Dragger
        {...uploadProps}
        style={{
          margin: '15px',
          color: 'black',
          border: '3px dashed #0080e5',
          width: '300px',
          cursor: 'pointer',
          borderRadius: '5px',
          textAlign: 'center',
        }}
      >
        <p className="ant-upload-text">Добавить файл</p>
        <p className="ant-upload-hint">
          Также можно перенести файл
        </p>
      </Dragger>
    </section>
  );
};

export default NewsPageFormDragger
