import * as React from 'react';
import { FC, useState } from 'react';
import { Col, Row, Space } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';
import { PartnerDto } from '../../generated/backend';
import AddDialog from '../common/AddDialog/AddDialog';
import { partnerApi } from '../../api/apis'
import PartnersTree from './partnersTree/PartnersTree'
import { useAppDispatch } from '../../store/store'
import AddButton from '../common/forTree/AddButton'
import { getAllPartners } from '../../store/slices/partners/partnersActions'

export const PartnersLayout: FC = () => {
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const onAddHandler = () => {
    setShowDialog(true);
  };

  const onCloseDialog = () => {
    setShowDialog(false);
  };

  const onSaveDialog = async (partner: PartnerDto) => {
    const createdPartner = await partnerApi.apiPartnerPost(partner);

    navigate(`/partners/${createdPartner.data.id}`);
    setShowDialog(false);

    await dispatch(getAllPartners());
  };

  return (
    <div>
      <Row justify={'space-around'}>
        <Col span={6}>
          <Space>
            <AddButton onClick={onAddHandler} />
          </Space>
          <PartnersTree />
        </Col>
        <Col span={17}>
          <Outlet />
        </Col>
      </Row>
      {showDialog && (
        <AddDialog
          isOpen={showDialog}
          onClose={onCloseDialog}
          onSave={onSaveDialog}
        />
      )}
    </div>
  );
};
