import { Tree } from 'antd'
import * as React from 'react'
import { Loader } from '../common/Loader/Loader'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { ReviewDto } from '../../generated/backend'
import TreeItem from '../common/forTree/TreeItem'
import { fileApi, reviewApi } from '../../api/apis'
import { useNavigate } from 'react-router-dom'
import { getAllReviews } from '../../store/slices/reviews/reviewsActions'
import { useEffect } from 'react'

const ReviewsTree = () => {
  const { reviews, loading } = useAppSelector(state => state.reviews.tree);
  const selectedReview = useAppSelector(state => state.reviews.selected.review);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onRemove = async (item: ReviewDto) => {
    navigate('/reviews')

    const review = await reviewApi.apiReviewIdGet(`${item.id}`)

    if (review.data.files.length !== 0) {
      for (let i = 0; i < review.data.files.length; i++) {
        await fileApi.apiFileIdDelete(review.data.files[i].id)
      }

      await reviewApi.apiReviewIdDelete(`${review.data.id}`)
      await dispatch(getAllReviews());

      return
    }

    await reviewApi.apiReviewIdDelete(`${review.data.id}`)
    await dispatch(getAllReviews());
  }

  const reviewToNode = (review: ReviewDto) => ({
    ...review,
    key: review.id,
    title: <TreeItem itemName={review.name} onDelete={() => onRemove(review)}/>
  });

  const onSelect = item => {
    navigate(`/reviews/${item.id}`)
  }

  useEffect(() => {
    (async () => {
      await dispatch(getAllReviews());
    })()
  }, [dispatch]);

  if (loading && !loading) {
    return <Loader />
  }
  return (
    <Tree
      treeData={reviews.map(reviewToNode)}
      selectedKeys={[selectedReview.id] || []}
      onSelect={(key, info) => {
        onSelect(info.node)
      }}
    />
  );
};

export default ReviewsTree;
