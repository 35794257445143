import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MenuState } from './types'
import { createMenuItem, deleteMenuItem, getMenuItem, getMenuTree } from './menuActions'
import { MenuItemDto } from '../../../generated/backend'

const initialState: MenuState = {
  selected: {
    item: null,
    loading: true,
  },
  tree: {
    expanded: [],
    items: [],
    loading: false,
  },
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setSelectedItem: (state, action: PayloadAction<Partial<MenuItemDto>>) => {
      state.selected.item = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMenuTree.pending, (state) => {
      if (!state.tree.items.length) {
        state.tree.loading = true;
      }
    });
    builder.addCase(getMenuTree.fulfilled, (state, action) => {
      state.tree.items = action.payload.items;
      state.tree.expanded = action.payload.expanded || [];
      state.tree.loading = false;
    });

    builder.addCase(getMenuItem.pending, (state) => {
      state.selected.loading = true;
    });
    builder.addCase(getMenuItem.fulfilled, (state, action) => {
      state.selected.item = action.payload;
      state.selected.loading = false;
    });

    builder.addCase(deleteMenuItem.pending, (state) => {
      state.tree.loading = true;
    });
    builder.addCase(deleteMenuItem.fulfilled, (state) => {
      state.tree.loading = false;
    });

    builder.addCase(createMenuItem.pending, (state) => {
      state.selected.loading = true;
    });
    builder.addCase(createMenuItem.fulfilled, (state, action) => {
      state.selected.item = action.payload;
      state.selected.loading = false;
    });
  },
});

export default menuSlice.reducer;

export const { setSelectedItem, } = menuSlice.actions;
