import { createSlice } from '@reduxjs/toolkit'
import { ReviewsState } from './types'
import { getAllReviews, getReview } from './reviewsActions'

const initialState: ReviewsState = {
  selected: {
    loading: true,
    review: {}
  },
  tree: {
    loading: true,
    reviews: []
  }
}

const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    clearSelected: (state) => {
      state.selected.review = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllReviews.pending, (state) => {
      state.tree.loading = true;
    });
    builder.addCase(getAllReviews.fulfilled, (state, action) => {
      state.tree.reviews = action.payload;
      state.tree.loading = false;
    });
    builder.addCase(getAllReviews.rejected, (state) => {
      state.tree.reviews = [];
      state.tree.loading = false;
    });

    builder.addCase(getReview.pending, (state) => {
      state.selected.loading = true;
    });
    builder.addCase(getReview.fulfilled, (state, action) => {
      state.selected.review = action.payload;
      state.selected.loading = false;
    });
    builder.addCase(getReview.rejected, (state) => {
      state.selected.loading = false;
    })
  },
});

export default reviewsSlice.reducer;

export const { clearSelected, } = reviewsSlice.actions;
