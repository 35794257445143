import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Home from '../../../assets/img/home.svg'
import { LoadingOutlined } from '@ant-design/icons'
import { useAppSelector } from '../../../store/store'

const Breadcrumbs = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const currentPageName = useAppSelector((state) => state.layout.currentPage)
  const [isEditPage, setIsEditPage] = useState(false);

  useEffect(() => {
    const isEdit = location.pathname.includes('edit');
    setIsEditPage(isEdit);
  }, [location])

  const paths = location.pathname.split('/')
    .filter((path) => path !== '')

  const getTitle = (path: string) => {
    switch (path) {
      case 'pages':
        return 'Страницы'
      case 'page':
        return 'Страница'
      case 'news-page':
        return 'Новости'
      case 'menu':
        return 'Meню'
      case 'partners':
        return 'Партнеры'
      case 'reviews':
        return 'Отзывы'
      case 'blocks':
        return 'Блоки'
      case 'settings':
        return 'Настройки'
      default:
        return currentPageName;
    }
  };

  const renderItemsForEditPage = () => {
    const currentPageId = paths[paths.length - 1];
    const path = location.pathname.includes('news-page') ? 'news-page' : 'pages';
    return (
      <>
        <li onClick={() => navigate('/')}>
          <Home />
        </li>
        <li onClick={() => navigate(`/${path}`)}>{path === 'pages' ? 'Страницы' : 'Новости'}</li>
        <li onClick={() => navigate(`/${path}/${currentPageId}`)}>{currentPageName || <LoadingOutlined rev="" />}</li>
        <li>Редактирование</li>
      </>
    );
  };

  const renderItemsForRegularPage = () => (
    <>
      <li onClick={() => navigate('/')}>
        <Home />
      </li>
      {paths.map((path, index) => {
        const routeTo = paths.slice(0, index + 1)
          .join('/')
        const isLast = index === paths.length - 1
        return isLast ? (
          <li key={index}>{getTitle(path) || <LoadingOutlined rev={undefined} />}</li>
        ) : (
          <li
            onClick={() => {
              navigate(`/${routeTo}`)
            }}
            key={index}
          >
            {getTitle(path) || <LoadingOutlined rev={undefined} />}
          </li>
        )
      })}
    </>
  );

  return (
    <>
      {isEditPage ? renderItemsForEditPage() : renderItemsForRegularPage()}
    </>
  )
}

export default Breadcrumbs
