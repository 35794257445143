import { Form, Input, Modal } from 'antd';
import React, { ChangeEvent, FC, useState } from 'react';
import { SectionDto } from '../../../generated/backend';
import { sectionsApi } from '../../../api/apis';

interface CreateSectionModal {
  open: boolean;
  onClose: () => void;
}

const CreateSectionModal: FC<CreateSectionModal> = (props) => {
  const { open, onClose } = props;
  // указание id - это костыль, по факту id присваивается бд-шкой
  const [section, setSection] = useState<SectionDto>({ id: 5 });

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSection({
      ...section,
      [e.target.name]: e.target.value,
    });
  };

  const onSave = async () => {
    await sectionsApi.apiSectionPost(section);
    onClose();
  };

  return (
    <Modal open={open}
      onCancel={onClose}
      onOk={onSave}
      cancelText={'Отмена'}
      okText={'Создать'}
      okButtonProps={{
        disabled: !(section.slug && section.title),
      }}
      centered
    >
      <Form>
        <Form.Item label={'Название'}>
          <Input value={section.title || ''} name="title" onChange={onChange}/>
        </Form.Item>


        <Form.Item label={'Slug'}>
          <Input value={section.slug || ''} name="slug" onChange={onChange}/>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateSectionModal;
