import Dragger from 'antd/es/upload/Dragger'
import * as React from 'react'
import { message, UploadProps } from 'antd'
import axios from 'axios'
import { getPartner } from '../../../store/slices/partners/partnersActions'
import { useAppDispatch } from '../../../store/store'
import { FC } from 'react'

interface PartnerFilesDragger {
  id: string;
}

const PartnerFilesDragger: FC<PartnerFilesDragger> = ({ id }) => {
  const dispatch = useAppDispatch();

  const props: UploadProps = {
    multiple: true,
    type: 'drag',
    progress: {
      format: percent => percent && `${parseFloat(percent.toFixed(2))}%`
    },
    customRequest: async info => {
      const { onSuccess, file, onProgress } = info;

      if (typeof file === 'string') return;

      const fmData = new FormData();
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          Size: file.size
        },
        onUploadProgress: event => {
          onProgress({ percent: (event.loaded / event.total) * 100 });
        }
      };

      fmData.append('image', file);

      try {
        await axios.post(
          `/api/file/upload?entityId=${id}&entityType=Partner`,
          fmData,
          config
        );
        onSuccess('Ok');

        await dispatch(getPartner(Number(id)));

        message.success('Файл успешно загружен');
      } catch (err) {
        message.error('Что-то пошло не так');
      }
    }
  };

  return (
    <section
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Dragger
        {...props}
        style={{
          margin: '15px',
          color: 'black',
          border: '3px dashed #0080e5',
          width: '300px',
          cursor: 'pointer',
          borderRadius: '5px',
          textAlign: 'center'
        }}
      >
        <p className='ant-upload-text'>Добавить файл</p>
        <p className='ant-upload-hint'>
          Также можно перенести файл
        </p>
      </Dragger>
    </section>
  );
};

export default PartnerFilesDragger;
