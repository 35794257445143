import { Table } from 'antd'
import { columnsForFiles } from '../../../helpers'
import * as React from 'react'
import { fileApi } from '../../../api/apis'
import { getPartner } from '../../../store/slices/partners/partnersActions'
import { useAppDispatch } from '../../../store/store'
import { FC } from 'react'
import { PartnerDto } from '../../../generated/backend'

interface PartnerFilesTable {
  id: string;
  data: PartnerDto;
}

const PartnerFilesTable: FC<PartnerFilesTable> = ({ id, data }) => {
  const dispatch = useAppDispatch();

  const onRemove = async (fileId: string) => {
    await fileApi.apiFileIdDelete(fileId);

    await dispatch(getPartner(Number(id)));
  };

  return (
    <Table
      columns={columnsForFiles(onRemove)}
      dataSource={data.files?.map((item, index) => ({
        ...item,
        key: index + 1
      }))}
      size={'small'}
      pagination={false}
    />
  );
};

export default PartnerFilesTable;
