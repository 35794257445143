/* tslint:disable */
/* eslint-disable */
/**
 * cms API
 * cms API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AuthRequest
 */
export interface AuthRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthRequest
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthRequest
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface AuthResponse
 */
export interface AuthResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthResponse
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface BlockDto
 */
export interface BlockDto {
    /**
     * 
     * @type {number}
     * @memberof BlockDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BlockDto
     */
    'name'?: string;
    /**
     * 
     * @type {PageDto}
     * @memberof BlockDto
     */
    'page'?: PageDto;
    /**
     * 
     * @type {number}
     * @memberof BlockDto
     */
    'pageId'?: number;
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof BlockDto
     */
    'files'?: Array<FileDto>;
    /**
     * 
     * @type {string}
     * @memberof BlockDto
     */
    'heading1'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockDto
     */
    'heading2'?: string;
    /**
     * 
     * @type {number}
     * @memberof BlockDto
     */
    'sort'?: number;
    /**
     * 
     * @type {string}
     * @memberof BlockDto
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockDto
     */
    'text'?: string;
    /**
     * 
     * @type {number}
     * @memberof BlockDto
     */
    'group'?: number;
    /**
     * 
     * @type {string}
     * @memberof BlockDto
     */
    'oldId'?: string;
}
/**
 * 
 * @export
 * @interface ChainItem
 */
export interface ChainItem {
    /**
     * 
     * @type {string}
     * @memberof ChainItem
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChainItem
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface FileDto
 */
export interface FileDto {
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FileDto
     */
    'main'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FileDto
     */
    'sort'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'extension'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'group'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'oldId'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileDto
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileDto
     */
    'width'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FileDto
     */
    'isMeta'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FileDto
     */
    'isHeader'?: boolean;
}
/**
 * 
 * @export
 * @interface MenuItemDto
 */
export interface MenuItemDto {
    /**
     * 
     * @type {number}
     * @memberof MenuItemDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuItemDto
     */
    'name'?: string;
    /**
     * 
     * @type {MenuTypeDto}
     * @memberof MenuItemDto
     */
    'menuType'?: MenuTypeDto;
    /**
     * 
     * @type {number}
     * @memberof MenuItemDto
     */
    'sort'?: number;
    /**
     * 
     * @type {PageDto}
     * @memberof MenuItemDto
     */
    'page'?: PageDto;
    /**
     * 
     * @type {MenuItemDto}
     * @memberof MenuItemDto
     */
    'parent'?: MenuItemDto;
    /**
     * 
     * @type {number}
     * @memberof MenuItemDto
     */
    'parentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuItemDto
     */
    'blockNumber'?: number;
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof MenuItemDto
     */
    'files'?: Array<FileDto>;
    /**
     * 
     * @type {string}
     * @memberof MenuItemDto
     */
    'url'?: string;
    /**
     * 
     * @type {StatusDto}
     * @memberof MenuItemDto
     */
    'status'?: StatusDto;
    /**
     * 
     * @type {Array<StatusDto>}
     * @memberof MenuItemDto
     */
    'statuses'?: Array<StatusDto>;
    /**
     * 
     * @type {Array<MenuTypeDto>}
     * @memberof MenuItemDto
     */
    'menuTypes'?: Array<MenuTypeDto>;
    /**
     * 
     * @type {number}
     * @memberof MenuItemDto
     */
    'childrenCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuItemDto
     */
    'oldId'?: string;
}
/**
 * 
 * @export
 * @interface MenuTypeDto
 */
export interface MenuTypeDto {
    /**
     * 
     * @type {string}
     * @memberof MenuTypeDto
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuTypeDto
     */
    'label'?: string;
}
/**
 * 
 * @export
 * @interface NewsPageDto
 */
export interface NewsPageDto {
    /**
     * 
     * @type {number}
     * @memberof NewsPageDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsPageDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsPageDto
     */
    'metaTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsPageDto
     */
    'metaDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsPageDto
     */
    'brief'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsPageDto
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsPageDto
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsPageDto
     */
    'youtrackId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsPageDto
     */
    'oldId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsPageDto
     */
    'publishedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsPageDto
     */
    'text'?: string;
    /**
     * 
     * @type {StatusDto}
     * @memberof NewsPageDto
     */
    'status'?: StatusDto;
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof NewsPageDto
     */
    'files'?: Array<FileDto>;
    /**
     * 
     * @type {Array<StatusDto>}
     * @memberof NewsPageDto
     */
    'statuses'?: Array<StatusDto>;
    /**
     * 
     * @type {Array<ChainItem>}
     * @memberof NewsPageDto
     */
    'navChain'?: Array<ChainItem>;
}
/**
 * 
 * @export
 * @interface PageDto
 */
export interface PageDto {
    /**
     * 
     * @type {number}
     * @memberof PageDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PageDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageDto
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageDto
     */
    'youtrackId'?: string;
    /**
     * 
     * @type {PageDto}
     * @memberof PageDto
     */
    'parent'?: PageDto;
    /**
     * 
     * @type {number}
     * @memberof PageDto
     */
    'parentId'?: number;
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof PageDto
     */
    'files'?: Array<FileDto>;
    /**
     * 
     * @type {Array<BlockDto>}
     * @memberof PageDto
     */
    'blocks'?: Array<BlockDto>;
    /**
     * 
     * @type {Array<PageDto>}
     * @memberof PageDto
     */
    'related'?: Array<PageDto>;
    /**
     * 
     * @type {StatusDto}
     * @memberof PageDto
     */
    'status'?: StatusDto;
    /**
     * 
     * @type {string}
     * @memberof PageDto
     */
    'metaTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageDto
     */
    'metaDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageDto
     */
    'topHeading'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageDto
     */
    'topText1'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageDto
     */
    'topText2'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageDto
     */
    'topComment'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageDto
     */
    'topCellValue1'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageDto
     */
    'topCellValue2'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageDto
     */
    'topCellValue3'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageDto
     */
    'topCellName1'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageDto
     */
    'topCellName2'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageDto
     */
    'topCellName3'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageDto
     */
    'htmlBlock1'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageDto
     */
    'callToAction'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageDto
     */
    'text'?: string;
    /**
     * 
     * @type {Array<ChainItem>}
     * @memberof PageDto
     */
    'navChain'?: Array<ChainItem>;
    /**
     * 
     * @type {number}
     * @memberof PageDto
     */
    'childrenCount'?: number;
    /**
     * 
     * @type {Array<StatusDto>}
     * @memberof PageDto
     */
    'statuses'?: Array<StatusDto>;
    /**
     * 
     * @type {string}
     * @memberof PageDto
     */
    'oldId'?: string;
    /**
     * 
     * @type {Array<SectionDto>}
     * @memberof PageDto
     */
    'sections'?: Array<SectionDto>;
    /**
     * 
     * @type {string}
     * @memberof PageDto
     */
    'brief'?: string;
}
/**
 * 
 * @export
 * @interface PartnerDto
 */
export interface PartnerDto {
    /**
     * 
     * @type {number}
     * @memberof PartnerDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDto
     */
    'oldId'?: string;
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof PartnerDto
     */
    'files'?: Array<FileDto>;
    /**
     * 
     * @type {StatusDto}
     * @memberof PartnerDto
     */
    'status'?: StatusDto;
    /**
     * 
     * @type {number}
     * @memberof PartnerDto
     */
    'sort'?: number;
}
/**
 * 
 * @export
 * @interface ReviewDto
 */
export interface ReviewDto {
    /**
     * 
     * @type {number}
     * @memberof ReviewDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReviewDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewDto
     */
    'oldId'?: string;
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof ReviewDto
     */
    'files'?: Array<FileDto>;
    /**
     * 
     * @type {StatusDto}
     * @memberof ReviewDto
     */
    'status'?: StatusDto;
    /**
     * 
     * @type {number}
     * @memberof ReviewDto
     */
    'sort'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReviewDto
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewDto
     */
    'contactName'?: string;
    /**
     * 
     * @type {PartnerDto}
     * @memberof ReviewDto
     */
    'partner'?: PartnerDto;
}
/**
 * 
 * @export
 * @interface SectionDto
 */
export interface SectionDto {
    /**
     * 
     * @type {number}
     * @memberof SectionDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionDto
     */
    'slug'?: string;
}
/**
 * 
 * @export
 * @interface SettingDto
 */
export interface SettingDto {
    /**
     * 
     * @type {string}
     * @memberof SettingDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingDto
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface StatusDto
 */
export interface StatusDto {
    /**
     * 
     * @type {string}
     * @memberof StatusDto
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatusDto
     */
    'label'?: string;
}
/**
 * 
 * @export
 * @interface TreeItemDto
 */
export interface TreeItemDto {
    /**
     * 
     * @type {number}
     * @memberof TreeItemDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TreeItemDto
     */
    'parentId'?: number;
    /**
     * 
     * @type {TreeItemDto}
     * @memberof TreeItemDto
     */
    'parent'?: TreeItemDto;
    /**
     * 
     * @type {string}
     * @memberof TreeItemDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TreeItemDto
     */
    'type'?: TreeItemDtoTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TreeItemDto
     */
    'selectable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TreeItemDto
     */
    'collapsible'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TreeItemDto
     */
    'childrenCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof TreeItemDto
     */
    'attributes'?: string;
}

export const TreeItemDtoTypeEnum = {
    Page: 'PAGE',
    Newspage: 'NEWSPAGE',
    Menuitem: 'MENUITEM',
    Root: 'ROOT'
} as const;

export type TreeItemDtoTypeEnum = typeof TreeItemDtoTypeEnum[keyof typeof TreeItemDtoTypeEnum];

/**
 * 
 * @export
 * @interface TreeItemResponseDto
 */
export interface TreeItemResponseDto {
    /**
     * 
     * @type {Array<TreeItemDto>}
     * @memberof TreeItemResponseDto
     */
    'items'?: Array<TreeItemDto>;
    /**
     * 
     * @type {Array<number>}
     * @memberof TreeItemResponseDto
     */
    'expanded'?: Array<number>;
}

/**
 * BlockApi - axios parameter creator
 * @export
 */
export const BlockApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlockDeleteAllDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/block/deleteAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlockGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/block`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlockIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBlockIdDelete', 'id', id)
            const localVarPath = `/api/block/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlockIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBlockIdGet', 'id', id)
            const localVarPath = `/api/block/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BlockDto} blockDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlockPost: async (blockDto: BlockDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blockDto' is not null or undefined
            assertParamExists('apiBlockPost', 'blockDto', blockDto)
            const localVarPath = `/api/block`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(blockDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BlockDto} blockDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlockPut: async (blockDto: BlockDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blockDto' is not null or undefined
            assertParamExists('apiBlockPut', 'blockDto', blockDto)
            const localVarPath = `/api/block`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(blockDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BlockApi - functional programming interface
 * @export
 */
export const BlockApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BlockApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBlockDeleteAllDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBlockDeleteAllDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBlockGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BlockDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBlockGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBlockIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBlockIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBlockIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlockDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBlockIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BlockDto} blockDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBlockPost(blockDto: BlockDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlockDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBlockPost(blockDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BlockDto} blockDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBlockPut(blockDto: BlockDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlockDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBlockPut(blockDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BlockApi - factory interface
 * @export
 */
export const BlockApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BlockApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlockDeleteAllDelete(options?: any): AxiosPromise<object> {
            return localVarFp.apiBlockDeleteAllDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlockGet(options?: any): AxiosPromise<Array<BlockDto>> {
            return localVarFp.apiBlockGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlockIdDelete(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.apiBlockIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlockIdGet(id: string, options?: any): AxiosPromise<BlockDto> {
            return localVarFp.apiBlockIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BlockDto} blockDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlockPost(blockDto: BlockDto, options?: any): AxiosPromise<BlockDto> {
            return localVarFp.apiBlockPost(blockDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BlockDto} blockDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBlockPut(blockDto: BlockDto, options?: any): AxiosPromise<BlockDto> {
            return localVarFp.apiBlockPut(blockDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BlockApi - object-oriented interface
 * @export
 * @class BlockApi
 * @extends {BaseAPI}
 */
export class BlockApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockApi
     */
    public apiBlockDeleteAllDelete(options?: AxiosRequestConfig) {
        return BlockApiFp(this.configuration).apiBlockDeleteAllDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockApi
     */
    public apiBlockGet(options?: AxiosRequestConfig) {
        return BlockApiFp(this.configuration).apiBlockGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockApi
     */
    public apiBlockIdDelete(id: string, options?: AxiosRequestConfig) {
        return BlockApiFp(this.configuration).apiBlockIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockApi
     */
    public apiBlockIdGet(id: string, options?: AxiosRequestConfig) {
        return BlockApiFp(this.configuration).apiBlockIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BlockDto} blockDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockApi
     */
    public apiBlockPost(blockDto: BlockDto, options?: AxiosRequestConfig) {
        return BlockApiFp(this.configuration).apiBlockPost(blockDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BlockDto} blockDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockApi
     */
    public apiBlockPut(blockDto: BlockDto, options?: AxiosRequestConfig) {
        return BlockApiFp(this.configuration).apiBlockPut(blockDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileDeleteAllDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/file/delete-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileDownloadGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/file/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFileIdDelete', 'id', id)
            const localVarPath = `/api/file/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFileIdGet', 'id', id)
            const localVarPath = `/api/file/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FileDto} fileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilePost: async (fileDto: FileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileDto' is not null or undefined
            assertParamExists('apiFilePost', 'fileDto', fileDto)
            const localVarPath = `/api/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FileDto} fileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilePut: async (fileDto: FileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileDto' is not null or undefined
            assertParamExists('apiFilePut', 'fileDto', fileDto)
            const localVarPath = `/api/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * отправлять по одному файлу
         * @param {string} [entityId] 
         * @param {string} [entityType] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileUploadPost: async (entityId?: string, entityType?: string, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/file/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (entityId !== undefined) {
                localVarQueryParameter['entityId'] = entityId;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entityType'] = entityType;
            }

            if (size !== undefined && size !== null) {
                localVarHeaderParameter['Size'] = String(JSON.stringify(size));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileDeleteAllDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileDeleteAllDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileDownloadGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileDownloadGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FileDto} fileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFilePost(fileDto: FileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFilePost(fileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FileDto} fileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFilePut(fileDto: FileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFilePut(fileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * отправлять по одному файлу
         * @param {string} [entityId] 
         * @param {string} [entityType] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileUploadPost(entityId?: string, entityType?: string, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileUploadPost(entityId, entityType, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileDeleteAllDelete(options?: any): AxiosPromise<object> {
            return localVarFp.apiFileDeleteAllDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileDownloadGet(id?: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiFileDownloadGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileGet(options?: any): AxiosPromise<Array<FileDto>> {
            return localVarFp.apiFileGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdDelete(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.apiFileIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdGet(id: string, options?: any): AxiosPromise<FileDto> {
            return localVarFp.apiFileIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FileDto} fileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilePost(fileDto: FileDto, options?: any): AxiosPromise<FileDto> {
            return localVarFp.apiFilePost(fileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FileDto} fileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilePut(fileDto: FileDto, options?: any): AxiosPromise<FileDto> {
            return localVarFp.apiFilePut(fileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * отправлять по одному файлу
         * @param {string} [entityId] 
         * @param {string} [entityType] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileUploadPost(entityId?: string, entityType?: string, size?: number, options?: any): AxiosPromise<FileDto> {
            return localVarFp.apiFileUploadPost(entityId, entityType, size, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileDeleteAllDelete(options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileDeleteAllDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileDownloadGet(id?: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileDownloadGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileGet(options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileIdDelete(id: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileIdGet(id: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FileDto} fileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFilePost(fileDto: FileDto, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFilePost(fileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FileDto} fileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFilePut(fileDto: FileDto, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFilePut(fileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * отправлять по одному файлу
     * @param {string} [entityId] 
     * @param {string} [entityType] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileUploadPost(entityId?: string, entityType?: string, size?: number, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileUploadPost(entityId, entityType, size, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MenuItemApi - axios parameter creator
 * @export
 */
export const MenuItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuItemDeleteAllDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/menu-item/deleteAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuItemGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/menu-item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuItemIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMenuItemIdDelete', 'id', id)
            const localVarPath = `/api/menu-item/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuItemIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMenuItemIdGet', 'id', id)
            const localVarPath = `/api/menu-item/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MenuItemDto} menuItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuItemPost: async (menuItemDto: MenuItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'menuItemDto' is not null or undefined
            assertParamExists('apiMenuItemPost', 'menuItemDto', menuItemDto)
            const localVarPath = `/api/menu-item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(menuItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuItemPublishedGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/menu-item/published`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MenuItemDto} menuItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuItemPut: async (menuItemDto: MenuItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'menuItemDto' is not null or undefined
            assertParamExists('apiMenuItemPut', 'menuItemDto', menuItemDto)
            const localVarPath = `/api/menu-item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(menuItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [expanded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTreeMenuItemGet: async (expanded?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tree/menu-item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (expanded) {
                localVarQueryParameter['expanded'] = expanded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MenuItemApi - functional programming interface
 * @export
 */
export const MenuItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MenuItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMenuItemDeleteAllDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMenuItemDeleteAllDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMenuItemGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MenuItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMenuItemGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMenuItemIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMenuItemIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMenuItemIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MenuItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMenuItemIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MenuItemDto} menuItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMenuItemPost(menuItemDto: MenuItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MenuItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMenuItemPost(menuItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMenuItemPublishedGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MenuItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMenuItemPublishedGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MenuItemDto} menuItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMenuItemPut(menuItemDto: MenuItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MenuItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMenuItemPut(menuItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [expanded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTreeMenuItemGet(expanded?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TreeItemResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTreeMenuItemGet(expanded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MenuItemApi - factory interface
 * @export
 */
export const MenuItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MenuItemApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuItemDeleteAllDelete(options?: any): AxiosPromise<object> {
            return localVarFp.apiMenuItemDeleteAllDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuItemGet(options?: any): AxiosPromise<Array<MenuItemDto>> {
            return localVarFp.apiMenuItemGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuItemIdDelete(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.apiMenuItemIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuItemIdGet(id: string, options?: any): AxiosPromise<MenuItemDto> {
            return localVarFp.apiMenuItemIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MenuItemDto} menuItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuItemPost(menuItemDto: MenuItemDto, options?: any): AxiosPromise<MenuItemDto> {
            return localVarFp.apiMenuItemPost(menuItemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuItemPublishedGet(options?: any): AxiosPromise<Array<MenuItemDto>> {
            return localVarFp.apiMenuItemPublishedGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MenuItemDto} menuItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMenuItemPut(menuItemDto: MenuItemDto, options?: any): AxiosPromise<MenuItemDto> {
            return localVarFp.apiMenuItemPut(menuItemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [expanded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTreeMenuItemGet(expanded?: Array<string>, options?: any): AxiosPromise<TreeItemResponseDto> {
            return localVarFp.apiTreeMenuItemGet(expanded, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MenuItemApi - object-oriented interface
 * @export
 * @class MenuItemApi
 * @extends {BaseAPI}
 */
export class MenuItemApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuItemApi
     */
    public apiMenuItemDeleteAllDelete(options?: AxiosRequestConfig) {
        return MenuItemApiFp(this.configuration).apiMenuItemDeleteAllDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuItemApi
     */
    public apiMenuItemGet(options?: AxiosRequestConfig) {
        return MenuItemApiFp(this.configuration).apiMenuItemGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuItemApi
     */
    public apiMenuItemIdDelete(id: string, options?: AxiosRequestConfig) {
        return MenuItemApiFp(this.configuration).apiMenuItemIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuItemApi
     */
    public apiMenuItemIdGet(id: string, options?: AxiosRequestConfig) {
        return MenuItemApiFp(this.configuration).apiMenuItemIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MenuItemDto} menuItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuItemApi
     */
    public apiMenuItemPost(menuItemDto: MenuItemDto, options?: AxiosRequestConfig) {
        return MenuItemApiFp(this.configuration).apiMenuItemPost(menuItemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuItemApi
     */
    public apiMenuItemPublishedGet(options?: AxiosRequestConfig) {
        return MenuItemApiFp(this.configuration).apiMenuItemPublishedGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MenuItemDto} menuItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuItemApi
     */
    public apiMenuItemPut(menuItemDto: MenuItemDto, options?: AxiosRequestConfig) {
        return MenuItemApiFp(this.configuration).apiMenuItemPut(menuItemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [expanded] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuItemApi
     */
    public apiTreeMenuItemGet(expanded?: Array<string>, options?: AxiosRequestConfig) {
        return MenuItemApiFp(this.configuration).apiTreeMenuItemGet(expanded, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NewsPageApi - axios parameter creator
 * @export
 */
export const NewsPageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPageBySlugSlugGet: async (slug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('apiNewsPageBySlugSlugGet', 'slug', slug)
            const localVarPath = `/api/news-page/by-slug/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPageDeleteAllDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/news-page/deleteAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPageGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/news-page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPageIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNewsPageIdDelete', 'id', id)
            const localVarPath = `/api/news-page/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * updates isMeta and isHeader flags
         * @param {string} id 
         * @param {FileDto} fileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPageIdFilesPut: async (id: string, fileDto: FileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNewsPageIdFilesPut', 'id', id)
            // verify required parameter 'fileDto' is not null or undefined
            assertParamExists('apiNewsPageIdFilesPut', 'fileDto', fileDto)
            const localVarPath = `/api/news-page/{id}/files`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPageIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNewsPageIdGet', 'id', id)
            const localVarPath = `/api/news-page/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPageLatestGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/news-page/latest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewsPageDto} newsPageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPagePost: async (newsPageDto: NewsPageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newsPageDto' is not null or undefined
            assertParamExists('apiNewsPagePost', 'newsPageDto', newsPageDto)
            const localVarPath = `/api/news-page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newsPageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPagePublishedGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/news-page/published`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewsPageDto} newsPageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPagePut: async (newsPageDto: NewsPageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newsPageDto' is not null or undefined
            assertParamExists('apiNewsPagePut', 'newsPageDto', newsPageDto)
            const localVarPath = `/api/news-page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newsPageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsPageApi - functional programming interface
 * @export
 */
export const NewsPageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewsPageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsPageBySlugSlugGet(slug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsPageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsPageBySlugSlugGet(slug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsPageDeleteAllDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsPageDeleteAllDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsPageGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NewsPageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsPageGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsPageIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsPageIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * updates isMeta and isHeader flags
         * @param {string} id 
         * @param {FileDto} fileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsPageIdFilesPut(id: string, fileDto: FileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsPageIdFilesPut(id, fileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsPageIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsPageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsPageIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsPageLatestGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NewsPageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsPageLatestGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewsPageDto} newsPageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsPagePost(newsPageDto: NewsPageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsPageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsPagePost(newsPageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsPagePublishedGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NewsPageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsPagePublishedGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewsPageDto} newsPageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsPagePut(newsPageDto: NewsPageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsPageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsPagePut(newsPageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NewsPageApi - factory interface
 * @export
 */
export const NewsPageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewsPageApiFp(configuration)
    return {
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPageBySlugSlugGet(slug: string, options?: any): AxiosPromise<NewsPageDto> {
            return localVarFp.apiNewsPageBySlugSlugGet(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPageDeleteAllDelete(options?: any): AxiosPromise<object> {
            return localVarFp.apiNewsPageDeleteAllDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPageGet(options?: any): AxiosPromise<Array<NewsPageDto>> {
            return localVarFp.apiNewsPageGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPageIdDelete(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.apiNewsPageIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * updates isMeta and isHeader flags
         * @param {string} id 
         * @param {FileDto} fileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPageIdFilesPut(id: string, fileDto: FileDto, options?: any): AxiosPromise<FileDto> {
            return localVarFp.apiNewsPageIdFilesPut(id, fileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPageIdGet(id: string, options?: any): AxiosPromise<NewsPageDto> {
            return localVarFp.apiNewsPageIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPageLatestGet(options?: any): AxiosPromise<Array<NewsPageDto>> {
            return localVarFp.apiNewsPageLatestGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewsPageDto} newsPageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPagePost(newsPageDto: NewsPageDto, options?: any): AxiosPromise<NewsPageDto> {
            return localVarFp.apiNewsPagePost(newsPageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPagePublishedGet(options?: any): AxiosPromise<Array<NewsPageDto>> {
            return localVarFp.apiNewsPagePublishedGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewsPageDto} newsPageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPagePut(newsPageDto: NewsPageDto, options?: any): AxiosPromise<NewsPageDto> {
            return localVarFp.apiNewsPagePut(newsPageDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NewsPageApi - object-oriented interface
 * @export
 * @class NewsPageApi
 * @extends {BaseAPI}
 */
export class NewsPageApi extends BaseAPI {
    /**
     * 
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsPageApi
     */
    public apiNewsPageBySlugSlugGet(slug: string, options?: AxiosRequestConfig) {
        return NewsPageApiFp(this.configuration).apiNewsPageBySlugSlugGet(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsPageApi
     */
    public apiNewsPageDeleteAllDelete(options?: AxiosRequestConfig) {
        return NewsPageApiFp(this.configuration).apiNewsPageDeleteAllDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsPageApi
     */
    public apiNewsPageGet(options?: AxiosRequestConfig) {
        return NewsPageApiFp(this.configuration).apiNewsPageGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsPageApi
     */
    public apiNewsPageIdDelete(id: string, options?: AxiosRequestConfig) {
        return NewsPageApiFp(this.configuration).apiNewsPageIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * updates isMeta and isHeader flags
     * @param {string} id 
     * @param {FileDto} fileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsPageApi
     */
    public apiNewsPageIdFilesPut(id: string, fileDto: FileDto, options?: AxiosRequestConfig) {
        return NewsPageApiFp(this.configuration).apiNewsPageIdFilesPut(id, fileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsPageApi
     */
    public apiNewsPageIdGet(id: string, options?: AxiosRequestConfig) {
        return NewsPageApiFp(this.configuration).apiNewsPageIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsPageApi
     */
    public apiNewsPageLatestGet(options?: AxiosRequestConfig) {
        return NewsPageApiFp(this.configuration).apiNewsPageLatestGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewsPageDto} newsPageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsPageApi
     */
    public apiNewsPagePost(newsPageDto: NewsPageDto, options?: AxiosRequestConfig) {
        return NewsPageApiFp(this.configuration).apiNewsPagePost(newsPageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsPageApi
     */
    public apiNewsPagePublishedGet(options?: AxiosRequestConfig) {
        return NewsPageApiFp(this.configuration).apiNewsPagePublishedGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewsPageDto} newsPageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsPageApi
     */
    public apiNewsPagePut(newsPageDto: NewsPageDto, options?: AxiosRequestConfig) {
        return NewsPageApiFp(this.configuration).apiNewsPagePut(newsPageDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PageApi - axios parameter creator
 * @export
 */
export const PageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * return all published page in section
         * @param {string} sectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPageBySectionSectionIdGet: async (sectionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sectionId' is not null or undefined
            assertParamExists('apiPageBySectionSectionIdGet', 'sectionId', sectionId)
            const localVarPath = `/api/page/by-section/{sectionId}`
                .replace(`{${"sectionId"}}`, encodeURIComponent(String(sectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPageBySlugSlugGet: async (slug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('apiPageBySlugSlugGet', 'slug', slug)
            const localVarPath = `/api/page/by-slug/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPageDeleteAllDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/page/deleteAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPageGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPageIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPageIdDelete', 'id', id)
            const localVarPath = `/api/page/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * updates isMeta and isHeader file flags
         * @param {string} id 
         * @param {FileDto} fileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPageIdFilesPut: async (id: string, fileDto: FileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPageIdFilesPut', 'id', id)
            // verify required parameter 'fileDto' is not null or undefined
            assertParamExists('apiPageIdFilesPut', 'fileDto', fileDto)
            const localVarPath = `/api/page/{id}/files`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPageIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPageIdGet', 'id', id)
            const localVarPath = `/api/page/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PageDto} pageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPagePost: async (pageDto: PageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageDto' is not null or undefined
            assertParamExists('apiPagePost', 'pageDto', pageDto)
            const localVarPath = `/api/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPagePublishedGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/page/published`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PageDto} pageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPagePut: async (pageDto: PageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageDto' is not null or undefined
            assertParamExists('apiPagePut', 'pageDto', pageDto)
            const localVarPath = `/api/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} relatedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPageRelatedAddIdRelatedIdPost: async (id: string, relatedId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPageRelatedAddIdRelatedIdPost', 'id', id)
            // verify required parameter 'relatedId' is not null or undefined
            assertParamExists('apiPageRelatedAddIdRelatedIdPost', 'relatedId', relatedId)
            const localVarPath = `/api/page/related/add/{id}/{relatedId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"relatedId"}}`, encodeURIComponent(String(relatedId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPageRelatedIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPageRelatedIdGet', 'id', id)
            const localVarPath = `/api/page/related/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} relatedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPageRelatedRemoveIdRelatedIdDelete: async (id: string, relatedId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPageRelatedRemoveIdRelatedIdDelete', 'id', id)
            // verify required parameter 'relatedId' is not null or undefined
            assertParamExists('apiPageRelatedRemoveIdRelatedIdDelete', 'relatedId', relatedId)
            const localVarPath = `/api/page/related/remove/{id}/{relatedId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"relatedId"}}`, encodeURIComponent(String(relatedId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [expanded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTreePageGet: async (expanded?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tree/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (expanded) {
                localVarQueryParameter['expanded'] = expanded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PageApi - functional programming interface
 * @export
 */
export const PageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PageApiAxiosParamCreator(configuration)
    return {
        /**
         * return all published page in section
         * @param {string} sectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPageBySectionSectionIdGet(sectionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPageBySectionSectionIdGet(sectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPageBySlugSlugGet(slug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPageBySlugSlugGet(slug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPageDeleteAllDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPageDeleteAllDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPageGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPageGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPageIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPageIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * updates isMeta and isHeader file flags
         * @param {string} id 
         * @param {FileDto} fileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPageIdFilesPut(id: string, fileDto: FileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPageIdFilesPut(id, fileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPageIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPageIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PageDto} pageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPagePost(pageDto: PageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPagePost(pageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPagePublishedGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPagePublishedGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PageDto} pageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPagePut(pageDto: PageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPagePut(pageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} relatedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPageRelatedAddIdRelatedIdPost(id: string, relatedId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPageRelatedAddIdRelatedIdPost(id, relatedId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPageRelatedIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPageRelatedIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} relatedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPageRelatedRemoveIdRelatedIdDelete(id: string, relatedId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPageRelatedRemoveIdRelatedIdDelete(id, relatedId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [expanded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTreePageGet(expanded?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TreeItemResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTreePageGet(expanded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PageApi - factory interface
 * @export
 */
export const PageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PageApiFp(configuration)
    return {
        /**
         * return all published page in section
         * @param {string} sectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPageBySectionSectionIdGet(sectionId: string, options?: any): AxiosPromise<Array<PageDto>> {
            return localVarFp.apiPageBySectionSectionIdGet(sectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPageBySlugSlugGet(slug: string, options?: any): AxiosPromise<PageDto> {
            return localVarFp.apiPageBySlugSlugGet(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPageDeleteAllDelete(options?: any): AxiosPromise<object> {
            return localVarFp.apiPageDeleteAllDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPageGet(options?: any): AxiosPromise<Array<PageDto>> {
            return localVarFp.apiPageGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPageIdDelete(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.apiPageIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * updates isMeta and isHeader file flags
         * @param {string} id 
         * @param {FileDto} fileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPageIdFilesPut(id: string, fileDto: FileDto, options?: any): AxiosPromise<FileDto> {
            return localVarFp.apiPageIdFilesPut(id, fileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPageIdGet(id: string, options?: any): AxiosPromise<PageDto> {
            return localVarFp.apiPageIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PageDto} pageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPagePost(pageDto: PageDto, options?: any): AxiosPromise<PageDto> {
            return localVarFp.apiPagePost(pageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPagePublishedGet(options?: any): AxiosPromise<Array<PageDto>> {
            return localVarFp.apiPagePublishedGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PageDto} pageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPagePut(pageDto: PageDto, options?: any): AxiosPromise<PageDto> {
            return localVarFp.apiPagePut(pageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} relatedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPageRelatedAddIdRelatedIdPost(id: string, relatedId: string, options?: any): AxiosPromise<object> {
            return localVarFp.apiPageRelatedAddIdRelatedIdPost(id, relatedId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPageRelatedIdGet(id: string, options?: any): AxiosPromise<Array<PageDto>> {
            return localVarFp.apiPageRelatedIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} relatedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPageRelatedRemoveIdRelatedIdDelete(id: string, relatedId: string, options?: any): AxiosPromise<object> {
            return localVarFp.apiPageRelatedRemoveIdRelatedIdDelete(id, relatedId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [expanded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTreePageGet(expanded?: Array<string>, options?: any): AxiosPromise<TreeItemResponseDto> {
            return localVarFp.apiTreePageGet(expanded, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PageApi - object-oriented interface
 * @export
 * @class PageApi
 * @extends {BaseAPI}
 */
export class PageApi extends BaseAPI {
    /**
     * return all published page in section
     * @param {string} sectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApi
     */
    public apiPageBySectionSectionIdGet(sectionId: string, options?: AxiosRequestConfig) {
        return PageApiFp(this.configuration).apiPageBySectionSectionIdGet(sectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApi
     */
    public apiPageBySlugSlugGet(slug: string, options?: AxiosRequestConfig) {
        return PageApiFp(this.configuration).apiPageBySlugSlugGet(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApi
     */
    public apiPageDeleteAllDelete(options?: AxiosRequestConfig) {
        return PageApiFp(this.configuration).apiPageDeleteAllDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApi
     */
    public apiPageGet(options?: AxiosRequestConfig) {
        return PageApiFp(this.configuration).apiPageGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApi
     */
    public apiPageIdDelete(id: string, options?: AxiosRequestConfig) {
        return PageApiFp(this.configuration).apiPageIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * updates isMeta and isHeader file flags
     * @param {string} id 
     * @param {FileDto} fileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApi
     */
    public apiPageIdFilesPut(id: string, fileDto: FileDto, options?: AxiosRequestConfig) {
        return PageApiFp(this.configuration).apiPageIdFilesPut(id, fileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApi
     */
    public apiPageIdGet(id: string, options?: AxiosRequestConfig) {
        return PageApiFp(this.configuration).apiPageIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PageDto} pageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApi
     */
    public apiPagePost(pageDto: PageDto, options?: AxiosRequestConfig) {
        return PageApiFp(this.configuration).apiPagePost(pageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApi
     */
    public apiPagePublishedGet(options?: AxiosRequestConfig) {
        return PageApiFp(this.configuration).apiPagePublishedGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PageDto} pageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApi
     */
    public apiPagePut(pageDto: PageDto, options?: AxiosRequestConfig) {
        return PageApiFp(this.configuration).apiPagePut(pageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} relatedId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApi
     */
    public apiPageRelatedAddIdRelatedIdPost(id: string, relatedId: string, options?: AxiosRequestConfig) {
        return PageApiFp(this.configuration).apiPageRelatedAddIdRelatedIdPost(id, relatedId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApi
     */
    public apiPageRelatedIdGet(id: string, options?: AxiosRequestConfig) {
        return PageApiFp(this.configuration).apiPageRelatedIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} relatedId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApi
     */
    public apiPageRelatedRemoveIdRelatedIdDelete(id: string, relatedId: string, options?: AxiosRequestConfig) {
        return PageApiFp(this.configuration).apiPageRelatedRemoveIdRelatedIdDelete(id, relatedId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [expanded] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApi
     */
    public apiTreePageGet(expanded?: Array<string>, options?: AxiosRequestConfig) {
        return PageApiFp(this.configuration).apiTreePageGet(expanded, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnerApi - axios parameter creator
 * @export
 */
export const PartnerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPartnerDeleteAllDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/partner/deleteAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPartnerGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/partner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPartnerIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPartnerIdDelete', 'id', id)
            const localVarPath = `/api/partner/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPartnerIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPartnerIdGet', 'id', id)
            const localVarPath = `/api/partner/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PartnerDto} partnerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPartnerPost: async (partnerDto: PartnerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerDto' is not null or undefined
            assertParamExists('apiPartnerPost', 'partnerDto', partnerDto)
            const localVarPath = `/api/partner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partnerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPartnerPublishedGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/partner/published`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PartnerDto} partnerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPartnerPut: async (partnerDto: PartnerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerDto' is not null or undefined
            assertParamExists('apiPartnerPut', 'partnerDto', partnerDto)
            const localVarPath = `/api/partner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partnerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerApi - functional programming interface
 * @export
 */
export const PartnerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPartnerDeleteAllDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPartnerDeleteAllDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPartnerGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPartnerGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPartnerIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPartnerIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPartnerIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPartnerIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PartnerDto} partnerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPartnerPost(partnerDto: PartnerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPartnerPost(partnerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPartnerPublishedGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPartnerPublishedGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PartnerDto} partnerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPartnerPut(partnerDto: PartnerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPartnerPut(partnerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerApi - factory interface
 * @export
 */
export const PartnerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPartnerDeleteAllDelete(options?: any): AxiosPromise<object> {
            return localVarFp.apiPartnerDeleteAllDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPartnerGet(options?: any): AxiosPromise<Array<PartnerDto>> {
            return localVarFp.apiPartnerGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPartnerIdDelete(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.apiPartnerIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPartnerIdGet(id: string, options?: any): AxiosPromise<PartnerDto> {
            return localVarFp.apiPartnerIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PartnerDto} partnerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPartnerPost(partnerDto: PartnerDto, options?: any): AxiosPromise<PartnerDto> {
            return localVarFp.apiPartnerPost(partnerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPartnerPublishedGet(options?: any): AxiosPromise<Array<PartnerDto>> {
            return localVarFp.apiPartnerPublishedGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PartnerDto} partnerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPartnerPut(partnerDto: PartnerDto, options?: any): AxiosPromise<PartnerDto> {
            return localVarFp.apiPartnerPut(partnerDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerApi - object-oriented interface
 * @export
 * @class PartnerApi
 * @extends {BaseAPI}
 */
export class PartnerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public apiPartnerDeleteAllDelete(options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).apiPartnerDeleteAllDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public apiPartnerGet(options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).apiPartnerGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public apiPartnerIdDelete(id: string, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).apiPartnerIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public apiPartnerIdGet(id: string, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).apiPartnerIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PartnerDto} partnerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public apiPartnerPost(partnerDto: PartnerDto, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).apiPartnerPost(partnerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public apiPartnerPublishedGet(options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).apiPartnerPublishedGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PartnerDto} partnerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public apiPartnerPut(partnerDto: PartnerDto, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).apiPartnerPut(partnerDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReviewApi - axios parameter creator
 * @export
 */
export const ReviewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReviewDeleteAllDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/review/deleteAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReviewGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReviewIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReviewIdDelete', 'id', id)
            const localVarPath = `/api/review/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReviewIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReviewIdGet', 'id', id)
            const localVarPath = `/api/review/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReviewDto} reviewDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReviewPost: async (reviewDto: ReviewDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reviewDto' is not null or undefined
            assertParamExists('apiReviewPost', 'reviewDto', reviewDto)
            const localVarPath = `/api/review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reviewDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReviewPublishedGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/review/published`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReviewDto} reviewDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReviewPut: async (reviewDto: ReviewDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reviewDto' is not null or undefined
            assertParamExists('apiReviewPut', 'reviewDto', reviewDto)
            const localVarPath = `/api/review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reviewDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReviewApi - functional programming interface
 * @export
 */
export const ReviewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReviewApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReviewDeleteAllDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReviewDeleteAllDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReviewGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReviewDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReviewGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReviewIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReviewIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReviewIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReviewIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReviewDto} reviewDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReviewPost(reviewDto: ReviewDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReviewPost(reviewDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReviewPublishedGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReviewDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReviewPublishedGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReviewDto} reviewDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReviewPut(reviewDto: ReviewDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReviewPut(reviewDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReviewApi - factory interface
 * @export
 */
export const ReviewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReviewApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReviewDeleteAllDelete(options?: any): AxiosPromise<object> {
            return localVarFp.apiReviewDeleteAllDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReviewGet(options?: any): AxiosPromise<Array<ReviewDto>> {
            return localVarFp.apiReviewGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReviewIdDelete(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.apiReviewIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReviewIdGet(id: string, options?: any): AxiosPromise<ReviewDto> {
            return localVarFp.apiReviewIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReviewDto} reviewDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReviewPost(reviewDto: ReviewDto, options?: any): AxiosPromise<ReviewDto> {
            return localVarFp.apiReviewPost(reviewDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReviewPublishedGet(options?: any): AxiosPromise<Array<ReviewDto>> {
            return localVarFp.apiReviewPublishedGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReviewDto} reviewDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReviewPut(reviewDto: ReviewDto, options?: any): AxiosPromise<ReviewDto> {
            return localVarFp.apiReviewPut(reviewDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReviewApi - object-oriented interface
 * @export
 * @class ReviewApi
 * @extends {BaseAPI}
 */
export class ReviewApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewApi
     */
    public apiReviewDeleteAllDelete(options?: AxiosRequestConfig) {
        return ReviewApiFp(this.configuration).apiReviewDeleteAllDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewApi
     */
    public apiReviewGet(options?: AxiosRequestConfig) {
        return ReviewApiFp(this.configuration).apiReviewGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewApi
     */
    public apiReviewIdDelete(id: string, options?: AxiosRequestConfig) {
        return ReviewApiFp(this.configuration).apiReviewIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewApi
     */
    public apiReviewIdGet(id: string, options?: AxiosRequestConfig) {
        return ReviewApiFp(this.configuration).apiReviewIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReviewDto} reviewDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewApi
     */
    public apiReviewPost(reviewDto: ReviewDto, options?: AxiosRequestConfig) {
        return ReviewApiFp(this.configuration).apiReviewPost(reviewDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewApi
     */
    public apiReviewPublishedGet(options?: AxiosRequestConfig) {
        return ReviewApiFp(this.configuration).apiReviewPublishedGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReviewDto} reviewDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewApi
     */
    public apiReviewPut(reviewDto: ReviewDto, options?: AxiosRequestConfig) {
        return ReviewApiFp(this.configuration).apiReviewPut(reviewDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SectionsApi - axios parameter creator
 * @export
 */
export const SectionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/section`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSectionIdDelete', 'id', id)
            const localVarPath = `/api/section/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionIdPut: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSectionIdPut', 'id', id)
            const localVarPath = `/api/section/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SectionDto} sectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionPost: async (sectionDto: SectionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sectionDto' is not null or undefined
            assertParamExists('apiSectionPost', 'sectionDto', sectionDto)
            const localVarPath = `/api/section`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sectionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SectionsApi - functional programming interface
 * @export
 */
export const SectionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SectionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SectionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionIdPut(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionIdPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SectionDto} sectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionPost(sectionDto: SectionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionPost(sectionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SectionsApi - factory interface
 * @export
 */
export const SectionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SectionsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionGet(options?: any): AxiosPromise<Array<SectionDto>> {
            return localVarFp.apiSectionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionIdDelete(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.apiSectionIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionIdPut(id: number, options?: any): AxiosPromise<SectionDto> {
            return localVarFp.apiSectionIdPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SectionDto} sectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionPost(sectionDto: SectionDto, options?: any): AxiosPromise<SectionDto> {
            return localVarFp.apiSectionPost(sectionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SectionsApi - object-oriented interface
 * @export
 * @class SectionsApi
 * @extends {BaseAPI}
 */
export class SectionsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionsApi
     */
    public apiSectionGet(options?: AxiosRequestConfig) {
        return SectionsApiFp(this.configuration).apiSectionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionsApi
     */
    public apiSectionIdDelete(id: string, options?: AxiosRequestConfig) {
        return SectionsApiFp(this.configuration).apiSectionIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionsApi
     */
    public apiSectionIdPut(id: number, options?: AxiosRequestConfig) {
        return SectionsApiFp(this.configuration).apiSectionIdPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SectionDto} sectionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionsApi
     */
    public apiSectionPost(sectionDto: SectionDto, options?: AxiosRequestConfig) {
        return SectionsApiFp(this.configuration).apiSectionPost(sectionDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingApi - axios parameter creator
 * @export
 */
export const SettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingDeleteAllDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/setting/deleteAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSettingIdDelete', 'id', id)
            const localVarPath = `/api/setting/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSettingIdGet', 'id', id)
            const localVarPath = `/api/setting/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SettingDto} settingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingPost: async (settingDto: SettingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingDto' is not null or undefined
            assertParamExists('apiSettingPost', 'settingDto', settingDto)
            const localVarPath = `/api/setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SettingDto} settingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingPut: async (settingDto: SettingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingDto' is not null or undefined
            assertParamExists('apiSettingPut', 'settingDto', settingDto)
            const localVarPath = `/api/setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingApi - functional programming interface
 * @export
 */
export const SettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingDeleteAllDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingDeleteAllDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SettingDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SettingDto} settingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingPost(settingDto: SettingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingPost(settingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SettingDto} settingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingPut(settingDto: SettingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingPut(settingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingApi - factory interface
 * @export
 */
export const SettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingDeleteAllDelete(options?: any): AxiosPromise<object> {
            return localVarFp.apiSettingDeleteAllDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingGet(options?: any): AxiosPromise<Array<SettingDto>> {
            return localVarFp.apiSettingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingIdDelete(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.apiSettingIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingIdGet(id: string, options?: any): AxiosPromise<SettingDto> {
            return localVarFp.apiSettingIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SettingDto} settingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingPost(settingDto: SettingDto, options?: any): AxiosPromise<SettingDto> {
            return localVarFp.apiSettingPost(settingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SettingDto} settingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingPut(settingDto: SettingDto, options?: any): AxiosPromise<SettingDto> {
            return localVarFp.apiSettingPut(settingDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingApi - object-oriented interface
 * @export
 * @class SettingApi
 * @extends {BaseAPI}
 */
export class SettingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public apiSettingDeleteAllDelete(options?: AxiosRequestConfig) {
        return SettingApiFp(this.configuration).apiSettingDeleteAllDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public apiSettingGet(options?: AxiosRequestConfig) {
        return SettingApiFp(this.configuration).apiSettingGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public apiSettingIdDelete(id: string, options?: AxiosRequestConfig) {
        return SettingApiFp(this.configuration).apiSettingIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public apiSettingIdGet(id: string, options?: AxiosRequestConfig) {
        return SettingApiFp(this.configuration).apiSettingIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SettingDto} settingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public apiSettingPost(settingDto: SettingDto, options?: AxiosRequestConfig) {
        return SettingApiFp(this.configuration).apiSettingPost(settingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SettingDto} settingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public apiSettingPut(settingDto: SettingDto, options?: AxiosRequestConfig) {
        return SettingApiFp(this.configuration).apiSettingPut(settingDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetMeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/getMe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthRequest} authRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserLoginPost: async (authRequest: AuthRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authRequest' is not null or undefined
            assertParamExists('apiUserLoginPost', 'authRequest', authRequest)
            const localVarPath = `/api/user/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userLogin] 
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUpdateTokenPost: async (userLogin?: string, refreshToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/updateToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userLogin !== undefined) {
                localVarQueryParameter['userLogin'] = userLogin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetMeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetMeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthRequest} authRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserLoginPost(authRequest: AuthRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserLoginPost(authRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [userLogin] 
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUpdateTokenPost(userLogin?: string, refreshToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUpdateTokenPost(userLogin, refreshToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetMeGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiUserGetMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthRequest} authRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserLoginPost(authRequest: AuthRequest, options?: any): AxiosPromise<AuthResponse> {
            return localVarFp.apiUserLoginPost(authRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userLogin] 
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUpdateTokenPost(userLogin?: string, refreshToken?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiUserUpdateTokenPost(userLogin, refreshToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetMeGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthRequest} authRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserLoginPost(authRequest: AuthRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserLoginPost(authRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userLogin] 
     * @param {string} [refreshToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserUpdateTokenPost(userLogin?: string, refreshToken?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserUpdateTokenPost(userLogin, refreshToken, options).then((request) => request(this.axios, this.basePath));
    }
}


