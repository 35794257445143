import { useAppDispatch, useAppSelector } from '../../../store/store'
import { Tree } from 'antd'
import * as React from 'react'
import { PartnerDto } from '../../../generated/backend'
import TreeItem from '../../common/forTree/TreeItem'
import { useNavigate } from 'react-router-dom'
import { fileApi, partnerApi } from '../../../api/apis'
import { useEffect } from 'react'
import { getAllPartners } from '../../../store/slices/partners/partnersActions'
import { Loader } from '../../common/Loader/Loader'

const PartnersTree = () => {
  const { partners, loading } = useAppSelector(state => state.partners.tree);
  const selectedPartner = useAppSelector(state => state.partners.selected.partner);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onRemove = async (item: PartnerDto) => {
    navigate('/partners');
    const partner = await partnerApi.apiPartnerIdGet(`${item.id}`);
    // todo refactor после выполнения задачи CMS-113 test.cms, dev.cms
    if (partner.data.files.length !== 0) {
      for (let i = 0; i < partner.data.files.length; i++) {
        await fileApi.apiFileIdDelete(`${partner.data.files[i].id}`);
      }
      await partnerApi.apiPartnerIdDelete(`${partner.data.id}`);
    } else {
      await partnerApi.apiPartnerIdDelete(`${partner.data.id}`);
    }
    await dispatch(getAllPartners());
  };

  const partnerToTree = (partner: PartnerDto) => ({
    ...partner,
    key: partner.id,
    title: <TreeItem itemName={partner.name} onDelete={() => onRemove(partner)} />
  });

  const onSelect = item => {
    navigate(`/partners/${item.id}`);
  };

  useEffect(() => {
    (async () => {
      await dispatch(getAllPartners());
    })()
  }, [dispatch]);

  if (loading && !partners.length) {
    return <Loader />
  }

  return (
    <Tree
      selectedKeys={[selectedPartner.id] || []}
      treeData={partners?.map(partnerToTree) || []}
      onSelect={(key, info) => {
        onSelect(info.node);
      }}
    />
  );
};

export default PartnersTree;
