import Header from './header/Header';
import Sider from './sider/Sider';
import Layout from './layout/Layout';
import Breadcrumbs from './breadcrumbs/Breadcrumbs';

export {
  Header,
  Sider,
  Breadcrumbs,
};

export default Layout;
