import React, { FC } from 'react';
import { DropDown } from './DropDown';

interface TreeItem {
  itemName: string;
  onDelete: () => void;
}

const TreeItem: FC<TreeItem>  = ({ itemName, onDelete }) => (
  <>
    {itemName}
    {' '}
    <span onClick={(e) => e.stopPropagation()}>
      {DropDown(itemName, onDelete)}
    </span>
  </>
);

export default TreeItem
