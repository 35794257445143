// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hhvKkmBbxCPA37P4w3aI{height:98vh;display:flex;align-items:center;justify-content:center;overflow-y:hidden}.WAPqKgoCW0kastbZw8kl{max-width:300px;padding:10px;border-radius:10px;box-shadow:0 0 35px -12px rgba(0,0,0,.2)}.XIO3z6cG9e_1O6ZqvKuB{margin:15px 0;text-align:center}.U5zRH5ZrAZoJlaItPbWY{float:right}.M_6iVDXNzLPEjSENaGAA .U5zRH5ZrAZoJlaItPbWY{float:left}.cSDGLdbGP7YjzKke62Gw{width:100%}", "",{"version":3,"sources":["webpack://./components/login/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CAGJ,sBACI,eAAA,CACA,YAAA,CACA,kBAAA,CACA,wCAAA,CAGJ,sBACI,aAAA,CACA,iBAAA,CAGJ,sBACI,WAAA,CAGJ,4CACI,UAAA,CAGJ,sBAEI,UAAA","sourcesContent":[".container{\n    height: 98vh;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    overflow-y: hidden;\n}\n\n.loginForm {\n    max-width: 300px;\n    padding: 10px;\n    border-radius: 10px;\n    box-shadow: 0 0 35px -12px rgba(0, 0, 0, 0.2);\n}\n\n.logo {\n    margin: 15px 0;\n    text-align: center;\n}\n\n.forgot {\n    float: right;\n}\n\n.ant-col-rtl .forgot{\n    float: left;\n}\n\n.button {\n    /*width: calc(unit * 25)*/\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "hhvKkmBbxCPA37P4w3aI",
	"loginForm": "WAPqKgoCW0kastbZw8kl",
	"logo": "XIO3z6cG9e_1O6ZqvKuB",
	"forgot": "U5zRH5ZrAZoJlaItPbWY",
	"ant-col-rtl": "M_6iVDXNzLPEjSENaGAA",
	"button": "cSDGLdbGP7YjzKke62Gw"
};
export default ___CSS_LOADER_EXPORT___;
