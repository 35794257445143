import { Button, Space } from 'antd'
import React, { FC } from 'react'

interface ButtonGroup {
  onSubmit: () => void;
  onClose: () => void;
  onEdit?: () => void;
}

const ButtonGroup: FC<ButtonGroup> = (props) => {
  const {
    onEdit,
    onClose,
    onSubmit,
  } = props

  return (
    <Space style={{ marginBottom: '12px' }}>
      <Button onClick={onSubmit} type={'primary'}>
        Сохранить
      </Button>
      {onEdit ? <Button onClick={onEdit}>
        Редактировать
      </Button> : null}
      <Button onClick={onClose}>Закрыть</Button>
    </Space>
  )
}

export default ButtonGroup
