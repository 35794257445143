import { LayoutState, MenuItem } from './types'

const menuItems: MenuItem[] = [
  {
    label: 'Главная',
    key: 'main',
    path: '/',
  },
  {
    label: 'Страницы',
    key: 'pages',
    path: '/pages',
  },
  {
    label: 'Новости',
    path: '/news-page',
    key: 'newsPages',
  },
  {
    label: 'Меню',
    path: '/menu',
    key: 'menu',
  },
  {
    label: 'Партнеры',
    path: '/partners',
    key: 'partners',
  },
  {
    label: 'Отзывы',
    path: '/reviews',
    key: 'reviews',
  },
  {
    label: 'Блоки',
    path: '/blocks',
    key: 'blocks',
  },
  {
    label: 'Настройки',
    path: '/settings',
    key: 'settings',
  },
];

export const projectList = [
  {value: 'cmsru', label: 'rbiconcept.ru'},
  {value: 'cmsde', label: 'rbiconcept.de'},
  {value: 'cmskz', label: 'rbiconcept.kz'},
  {value: 'cmsen', label: 'rbiconcept.com'},
]

export const initialState: LayoutState = {
  currentPage: '',
  currentProject: { value: null, label: 'Не выбрано' },
  projectList,
  siderMenu: {
    menuItems,
    activeItem: null,
  },
}
