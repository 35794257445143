import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import mermaid from 'mermaid';
import { getCodeString } from 'rehype-rewrite';

mermaid.initialize({
    startOnLoad: true
});

const randomId = () => {
    return parseInt(String(Math.random() * 1e15), 10).toString(36);
};

const getCode = (arr = []) =>
    arr
        .map(dt => {
            if (typeof dt === 'string') {
                return dt;
            }
            if (dt.props && dt.props.children) {
                return getCode(dt.props.children);
            }
            return false;
        })
        .filter(Boolean)
        .join('');

export const Code = ({ inline, children = [], className, ...props }: any) => {
    const mermaidId = useRef(`mermaid-${randomId()}`);
    const [container, setContainer] = useState(null);

    const isMermaid =
        className && /^language-mermaid/.test(className.toLocaleLowerCase());
    const txt = children[0] || '';

    const code =
        props.node && props.node.children
            ? getCodeString(props.node.children)
            : txt;
    useEffect(() => {
        if (container && isMermaid) {
            try {
                const str = mermaid.render(mermaidId.current, code);
                container.innerHTML = str;
            } catch (error) {
                container.innerHTML = error;
            }
        }
    }, [container, isMermaid, code, mermaidId]);

    const refElement = useCallback(node => {
        if (node !== null) {
            setContainer(node);
        }
    }, []);

    const child = isMermaid ? null : children;
    return (
        <>
            <code id={mermaidId.current} style={{ display: 'none' }} />
            <code ref={refElement} data-name='mermaid'>
                {child}
            </code>
        </>
    );
};
