import React, { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Form, message, Row, Space } from 'antd'
import dayjs from 'dayjs'
import { Loader } from '../common/Loader/Loader'
import { NewsPageDto } from '../../generated/backend'
import { fileApi, newsPageApi } from '../../api/apis'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { getAllNewsPages, getNewsPageById } from '../../store/slices/newsPages/newsPagesActions'
import NewsPageTable from './newsPageFrom/NewsPageTable'
import NewsPageFormDragger from './newsPageFrom/NewsPageFormDragger'
import FormInput from '../common/forForm/FormInput'
import SimpleSelector from '../common/forForm/SimpleSelector'
import NewsPageDatepicker from './newsPageFrom/NewsPageDatepicker'
import ButtonGroup from '../common/forForm/ButtonGroup'

const NewsPageForm = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { page, loading, } = useAppSelector((state) => state.news.selected)
  const [data, setData] = useState<NewsPageDto>(undefined)
  const dispatch = useAppDispatch()

  console.log(data)

  useEffect(() => {
    (async () => {
      await dispatch(getNewsPageById(Number(id)));
    })()
  }, [dispatch, id])

  useEffect(() => {
    setData(page)
  }, [page])

  const onSubmit = async () => {
    await newsPageApi.apiNewsPagePut(data);
    message.success('Сохранено!');
    dispatch(getAllNewsPages());
  }

  const onCloseHandler = () => {
    navigate('/news-page');
  }

  const onRemoveFile = async (fileId: string) => {
    await fileApi.apiFileIdDelete(fileId);
    await dispatch(getAllNewsPages());
  }

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [e.target.name]: e.target.value || '',
    })
  }

  if (loading || !data) {
    return <Loader />
  }

  return (
    <div>
      <Form labelCol={{
        span: 3,
        offset: 0,
      }} labelAlign={'left'}>
        <ButtonGroup  onSubmit={onSubmit} onClose={onCloseHandler} onEdit={() => navigate(`/edit/news-page/${id}`)} />
        <Row>

          <FormInput fieldName={'name'} value={data.name} onChange={onInputChange} />

          <FormInput fieldName={'metaTitle'} value={data.metaTitle} onChange={onInputChange} />

          <FormInput fieldName={'metaDescription'} value={data.metaDescription} onChange={onInputChange} />

          <FormInput fieldName={'brief'} value={data.brief} onChange={onInputChange} />

          <FormInput fieldName={'slug'} value={data.slug} onChange={onInputChange} />

          <FormInput fieldName={'youtrackId'} value={data.youtrackId} onChange={onInputChange} />

          <NewsPageDatepicker
            value={
              dayjs(data.publishedDate)
                .isValid()
                ? dayjs(data.publishedDate)
                : undefined
            }
            onChange={(newValue) => setData({ ...data, ...newValue })}
          />

          <SimpleSelector
            label="status"
            value={data.status?.value}
            onChange={(newValue) => setData({ ...data, ...newValue })}
            options={data.statuses}
          />

        </Row>
      </Form>

      <NewsPageTable files={data?.files || []} onRemove={onRemoveFile} />
      <NewsPageFormDragger id={data.id} />
    </div>
  )
}

export default NewsPageForm
