import { Table } from 'antd'
import { columnsForFiles } from '../../../helpers'
import React, { FC } from 'react'
import { FileDto } from '../../../generated/backend'

interface NewsPageTable {
  onRemove: (_fileId: string) => void;
  files: FileDto[];
}

const NewsPageTable: FC<NewsPageTable> = (props) => {
  const { onRemove, files } = props;
  return (
    <Table
      columns={columnsForFiles(onRemove)}
      dataSource={files?.map((item, index) => ({
        ...item,
        key: index + 1,
      }))}
      size={'small'}
    />
  )
}

export default NewsPageTable;
