import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Logo from './../../assets/img/logo-black.svg';
import { RootState, useAppDispatch } from '../../store/store';
import {
  changePassword,
  changeUsername,
  login
} from '../../store/generalSlice';
import styles from './styles.module.scss';
import { Button, Checkbox, Form, Input } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import ProjectChoose from '../common/ProjectChoose/ProjectChoose';

export function Login() {
  const generalState = useSelector((state: RootState) => state.general);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (generalState.token) {
      navigate('/');
    }
  });

  return (
    <div className={styles.container}>
      <Form className={styles.loginForm}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Введите имя пользователя'
            }
          ]}
        >
          <Input
            prefix={<UserOutlined rev={undefined} />}
            placeholder="Имя пользовотеля"
            value={generalState.username}
            onChange={event =>
              dispatch(changeUsername(event.target.value))
            }
          />
        </Form.Item>
        <Form.Item>
          <Input.Password
            prefix={<LockOutlined rev={undefined} />}
            placeholder={'Пароль'}
            value={generalState.password}
            onChange={event =>
              dispatch(changePassword(event.target.value))
            }
          />
        </Form.Item>
        <Form.Item>
          <ProjectChoose
            bordered
          />
        </Form.Item>
        <Form.Item>
          <Checkbox>Запомнить</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button
            htmlType={'submit'}
            type={'primary'}
            className={styles.button}
            onClick={event => {
              event.preventDefault();
              dispatch(
                login({
                  username: generalState.username,
                  password: generalState.password
                })
              );
            }}
            disabled={!generalState.password || !generalState.username}
          >
            Войти
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
