import * as React from 'react';
import { FC, useState } from 'react';
import { SettingDto } from '../../generated/backend';
import { Button, Form, Input, Modal, Space } from 'antd';

interface AddSettingDialogProps {
    onClose: () => void;
    onSave: (_setting: SettingDto) => void;
    isOpen: boolean;
}

const AddSettingModal: FC<AddSettingDialogProps> = ({
  onClose,
  onSave,
  isOpen
}) => {
  const [state, setState] = useState<SettingDto>({});

  const handleSave = () => {
    onSave(state);
  };

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return (
    <Modal
      open={isOpen}
      centered
      title={'Добавить элемент'}
      footer={[]}
      onCancel={onClose}
    >
      <Form>
        <Form.Item>
          <Input
            name={'id'}
            value={state?.id}
            onChange={handleChange}
            allowClear
            placeholder={'Введите id'}
          />
        </Form.Item>
        <Form.Item>
          <Input
            name={'name'}
            value={state?.name}
            onChange={handleChange}
            allowClear
            placeholder={'Введите name'}
          />
        </Form.Item>
        <Form.Item>
          <Input
            name={'value'}
            value={state?.value}
            onChange={handleChange}
            allowClear
            placeholder={'Введите value'}
          />
        </Form.Item>
        <Space>
          <Button
            onClick={async () => {
              await handleSave();
            }}
            data-test={'addItemButton'}
            type={'primary'}
          >
                        Добавить
          </Button>
          <Button
            onClick={() => {
              {
                onClose();
              }
            }}
          >
                        Отмена
          </Button>
        </Space>
      </Form>
    </Modal>
  );
};

export default AddSettingModal;
