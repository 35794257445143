import { Col, DatePicker, Form } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { FC } from 'react';

interface NewsPageDatepicker {
  value: Dayjs | undefined;
  onChange: (_newValue: {publishedDate: string}) => void;
}

const NewsPageDatepicker: FC<NewsPageDatepicker> = (props) => {
  const { value, onChange } = props;

  const dateFormat = 'YYYY-MM-DD';

  return (
    <Col span={24}>
      <Form.Item label={'publishedDate'}>
        <DatePicker
          placeholder={'Выберите дату'}
          value={
            dayjs(value)
              .isValid()
              ? dayjs(value)
              : undefined
          }
          format={dateFormat}
          onChange={(_, dateStr) => onChange({publishedDate: dateStr})}
          style={{ width: '100%' }}
        />
      </Form.Item>
    </Col>
  );
};

export default NewsPageDatepicker;
