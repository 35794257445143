import React from 'react';
import axios from 'axios';
import { Modal, notification } from 'antd';

const getTenantId = () => {
  return localStorage.getItem('currentProjectId');
};

axios.interceptors.request.use(
  config => {
    if (localStorage.getItem('token')) {
      config.headers.Authorization = `Bearer ${localStorage.getItem(
        'token',
      )}`;
    }
    const tenantId = getTenantId();
    if (tenantId) {
      config.headers['x-tenant-id'] = tenantId;
      return config;
    }
    if (window.location.pathname !== '/login') {
      notification.error({
        message: 'Выберите проект'
      })
    }
  },
  error => Promise.reject(error),
);

const errorModal = (error: string) => {
  Modal.error({
    title: 'Ошибка',
    content: (
      <div style={{ overflow: 'auto', height: 'calc(100vh - 300px)' }}>
        {error}
      </div>
    ),
    width: 1000,
    onOk() {
      Modal.destroyAll();
    },
  });
};

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error.response.status === 401 &&
            window.location.pathname !== '/login'
    ) {
      localStorage.removeItem('token');
      location.replace('/login');
    }
    // todo all 500 check
    if (error.response.status >= 500) {
      console.log(error);
      notification.error({
        message: 'Сервер не отвечает',
        description: (
          <>
                        Пожалуйста, попробуйте позже.
            <div
              onClick={() => {
                errorModal(error.response.data);
              }}
              style={{ cursor: 'pointer', color: '#1677ff' }}
            >
                            Подробнее
            </div>
          </>
        ),
        duration: 3,
      });
    }
  },
);
