import * as React from 'react';
import { FC } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './Loader.module.scss';

type LoaderProps = {
    general?: boolean;
};
export const Loader: FC<LoaderProps> = ({ general }) => {
  return (
    <div className={general ? styles.generalContainer : styles.container}>
      <Spin
        size={'large'}
        indicator={
          <LoadingOutlined
            style={{ fontSize: 50 }}
            spin
            rev=""
          />
        }
      />
    </div>
  );
};
