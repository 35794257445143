import * as React from 'react';
import { ChangeEvent, FC, useEffect, useState } from 'react'
import {
  Form,
  message,
  Row
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { ReviewDto } from '../../generated/backend';
import { Loader } from '../common/Loader/Loader';
import { reviewApi } from '../../api/apis';
import ButtonGroup from '../common/forForm/ButtonGroup'
import FormInput from '../common/forForm/FormInput'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { getReview } from '../../store/slices/reviews/reviewsActions'
import ReviewFilesTable from './ReviewFilesTable'
import ReviewFileDragger from './ReviewFileDragger'
import { clearSelected } from '../../store/slices/reviews/reviewsSlice'

export const ReviewsForm: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [data, setData] = useState<ReviewDto>(undefined);
  const { review, loading } = useAppSelector(state => state.reviews.selected);

  useEffect(() => {
    (async () => {
      await dispatch(getReview(Number(id)));
    })();
    return () => {
      dispatch(clearSelected());
    }
  }, [dispatch, id]);

  useEffect(() => {
    setData(review);
  }, [review]);

  const onSubmit = async ()  => {
    await reviewApi.apiReviewPut(data);
    message.success('Сохранено!');
    await dispatch(getReview(Number(id)));
  };

  const onCloseHandler = () => {
    navigate('/reviews');
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [e.target.name]: e.target.value || '',
    })
  }

  if (!data && loading) {
    return <Loader />;
  }

  return (
    <div>
      <Form labelCol={{ span: 2, offset: 0 }} labelAlign={'left'}>
        <ButtonGroup onSubmit={onSubmit} onClose={onCloseHandler} />
        <Row>
          <FormInput fieldName={'name'} value={data?.name} onChange={onInputChange} />

          <FormInput fieldName={'contactName'} value={data?.contactName} onChange={onInputChange} />

          <FormInput fieldName={'text'} value={data?.text} onChange={onInputChange} />
        </Row>
      </Form>

      <ReviewFilesTable id={id} data={data} />

      <ReviewFileDragger id={id} />
    </div>
  );
};
