import React, { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, message, Row, } from 'antd'
import { Loader } from '../common/Loader/Loader'
import { MenuItemDto, } from '../../generated/backend'
import { menuApi, } from '../../api/apis'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { setSelectedItem } from '../../store/slices/menu/menuSlice'
import { getMenuItem, getMenuTree } from '../../store/slices/menu/menuActions'
import ButtonGroup from '../common/forForm/ButtonGroup'
import FormInput from '../common/forForm/FormInput'
import SelectorWithSearch from '../pages/pagesForm/SelectorWithSearch'
import SimpleSelector from '../common/forForm/SimpleSelector'

const MenuItemForm = () => {
  const { item, loading, } = useAppSelector(state => state.menu.selected);
  const { expanded } = useAppSelector(state => state.menu.tree);
  const [formData, setFormData] = useState<MenuItemDto>({});
  const navigate = useNavigate()
  const { id } = useParams()
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      dispatch(getMenuItem(id));
    })();
    return () => {
      dispatch(setSelectedItem(null));
    }
  }, [dispatch, id]);

  useEffect(() => {
    setFormData(item);
  }, [item]);

  const onSubmit = async () => {
    await menuApi.apiMenuItemPut(formData);
    message.success('Сохранено!');
    await dispatch(getMenuTree(expanded));
  };

  const onCloseHandler = () => {
    navigate('/menu/');
  };

  const onSelectChange = (value:  Partial<MenuItemDto>) => {
    setFormData({
      ...formData,
      ...value,
    })
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value || '',
    });
  };

  if (loading) {
    return <Loader />
  }

  return (
    <div>
      <Form labelCol={{
        span: 2,
        offset: 0,
      }} labelAlign={'left'}>
        <ButtonGroup onSubmit={onSubmit} onClose={onCloseHandler} />
        <Row>
          <SelectorWithSearch
            labelName={'parent'}
            entityType="menu"
            value={formData?.parent?.name ? formData?.parent?.name : formData?.parent?.id}
            onSelectChange={onSelectChange}
          />
          <FormInput
            fieldName={'name'}
            value={formData?.name}
            onChange={onInputChange}
          />
          <SimpleSelector
            label="status"
            options={formData?.statuses}
            value={formData?.status?.value || 'DRAFT'}
            onChange={onSelectChange}
          />
          <SelectorWithSearch
            labelName={'page'}
            entityType={'page'}
            value={formData?.page?.name ? formData?.page?.name : formData?.page?.id}
            onSelectChange={onSelectChange}
          />
          <SimpleSelector
            label="menuType"
            options={formData?.menuTypes || []}
            value={formData?.menuType?.value}
            onChange={onSelectChange}
          />
          <FormInput
            fieldName={'blockNumber'}
            value={formData?.blockNumber}
            onChange={onInputChange}
          />
        </Row>
      </Form>
    </div>
  )
}

export default MenuItemForm;
