import React, { ChangeEvent, FC } from 'react';
import { Col, ColProps, Form, Input } from 'antd';
import { FormItemLabelProps } from 'antd/es/form/FormItemLabel';
import { FormItemInputProps } from 'antd/es/form/FormItemInput';

interface FormInput {
  small?: boolean;
  medium?: boolean;
  fieldName: string;
  value: string | number;
  onChange: (_e: ChangeEvent<HTMLInputElement>) => void
  maxLength?: number;
}

const FormInput: FC<FormInput> = (props) => {
  const { small, medium, fieldName, value, onChange, maxLength } = props;

  const colProps: ColProps = {
    span: small && 8 || medium && 12 || 24,
  };

  const formItemProps: FormItemLabelProps & FormItemInputProps = {};

  if (small) {
    formItemProps.wrapperCol = { span: 17 };
    formItemProps.labelCol = { span: 6, offset: 0 };
  }

  if (medium) {
    formItemProps.wrapperCol = { span: 18 }
    formItemProps.labelCol = { span: 4, offset: 0 };
  }

  return (
    <Col {...colProps}>
      <Form.Item label={fieldName} {...formItemProps}>
        <Input
          name={fieldName}
          value={value || ''}
          onChange={onChange}
          maxLength={maxLength || 10000}
        />
      </Form.Item>
    </Col>
  );
};

export default FormInput;
