import React, { FC, useState } from 'react'
import {
  BlockDto,
  MenuItemDto,
  NewsPageDto,
  PageDto,
  PartnerDto,
  ReviewDto,
  TreeItemDto,
} from '../../../generated/backend'
import { Button, Form, Input, Modal, Space } from 'antd'

interface AddPageDialogProps {
  parent?: TreeItemDto;
  onClose: () => void;
  onSave: <TypeOfPage>(_page: TypeOfPage) => void;
  isOpen: boolean;
}

type EntityForSave = PageDto | BlockDto | MenuItemDto | NewsPageDto | PartnerDto | ReviewDto;
type EntityWithParent = PageDto | MenuItemDto;

const HierarchyItemDialog: FC<AddPageDialogProps> = ({
  parent,
  onClose,
  onSave,
  isOpen,
}) => {
  const [state, setState] = useState<EntityForSave>({})

  const handleSave = async () => {
    const entityForSave: EntityWithParent | EntityForSave = {...state};
    if (parent && Object.hasOwn(parent, 'id')) {
      (entityForSave as EntityWithParent).parent = { id: parent.id };
    }
    onSave(entityForSave);
  }

  const handleChange = e => {
    setState({
      [e.target.name]: e.target.value,
    })
  }

  return (
    <Modal
      open={isOpen}
      centered
      title={'Добавить элемент'}
      footer={[]}
      onCancel={onClose}
    >
      <Form>
        {parent?.id ? <h4>Родительский элемент - {parent.name}</h4> : null}
        <Form.Item>
          <Input
            name={'name'}
            defaultValue={state.name}
            onChange={handleChange}
            allowClear
          />
        </Form.Item>
        <Space>
          <Button
            onClick={async () => {
              await handleSave()
            }}
            data-test={'addItemButton'}
            type={'primary'}
          >
            Добавить
          </Button>
          <Button
            onClick={() => {
              {
                onClose()
              }
            }}
          >
            Отмена
          </Button>
        </Space>
      </Form>
    </Modal>
  )
}

export default HierarchyItemDialog
