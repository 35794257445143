import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import styles from './sider.module.css';
import SiderItemMenu from './SiderItemMenu';
import { useLocation } from 'react-router-dom'
import { setActiveItem } from '../../../store/slices/layout/layoutSlice'

const Sider = () => {
  const { menuItems, activeItem } = useAppSelector((state) => state.layout.siderMenu);
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const selectActiveItem = () => {
      const currentPath = location.pathname.split('/')[1];
      if (currentPath === '') {
        dispatch(setActiveItem('main'));
      } else if(currentPath === 'edit') {
        return;
      }
      else {
        const currentMenuItem = menuItems.find((menuItem) => {
          const isMenuItem = menuItem.path.includes(currentPath);
          if (isMenuItem) {
            return menuItem;
          }
        });
        dispatch(setActiveItem(currentMenuItem.key));
      }
    };

    selectActiveItem();
  }, [dispatch, location, menuItems]);

  return (
    <div className={styles.sider}>
      {menuItems.map((item) => (
        <SiderItemMenu item={item} activeItem={activeItem} key={item.path} />
      ))}
    </div>
  );
};

export default Sider;
