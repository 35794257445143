import { Button, List, Space, TreeSelect } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { listToTree } from '../../common/forTree/listToTree';
import React, { useEffect, useState } from 'react';
import { PageDto } from '../../../generated/backend';
import { pageApi } from '../../../api/apis';

const RelatedPagesTab = (props) => {

  const { id } = props;
  const [selectedPage, setSelectedPage] = useState<string>('');
  const [pages, setPages] = useState<PageDto[]>([]);
  const [searchParent, setSearchParent] = useState<PageDto[]>([]);

  const getRelatedPages = async () => {
    const re = await pageApi.apiPageRelatedIdGet(id);

    setPages(re.data);
  };

  const getParentsForPages = async () => {
    const re = await pageApi.apiPageGet();

    setSearchParent(re.data);
  };

  const addPage = async (relatedId: string) => {
    await pageApi.apiPageRelatedAddIdRelatedIdPost(id, relatedId);

    await getRelatedPages();
  };

  const handleDelete = async relatedId => {
    await pageApi.apiPageRelatedRemoveIdRelatedIdDelete(id, relatedId);

    await getRelatedPages();
  };


  const findPage = (selectedPage) => {
    const findPage = searchParent?.find(item => item.id === selectedPage);

    return findPage?.name;
  };

  useEffect(() => {
    getRelatedPages();
    getParentsForPages();
  }, [id]);

  return (
    <Space direction={'vertical'}>
      <List
        dataSource={pages}
        bordered
        renderItem={item => (
          <List.Item
            actions={[
              <Button
                icon={
                  <DeleteOutlined rev={undefined}/>
                }
                type={'link'}
                onClick={() => handleDelete(item.id)}
              />
            ]}
          >
            {item.name}
          </List.Item>
        )}
        pagination={{
          pageSize: 10,
          responsive: true,
          align: 'start',
          showSizeChanger: false,
          hideOnSinglePage: true
        }}
      />
      <Space>
        <TreeSelect
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          treeData={
            searchParent &&
            listToTree(
              searchParent?.map(item => ({
                ...item,
                value: item.name,
                title: item.name
              }))
            )
          }
          onSelect={(value, node) => setSelectedPage(node.id)}
          onFocus={getParentsForPages}
          style={{ width: '400px' }}
          value={findPage(selectedPage) || ''}
          treeLine
          showSearch
          allowClear
        />
        <Button
          type={'primary'}
          onClick={() => {
            if (selectedPage) {
              addPage(selectedPage);
              setSelectedPage('');
            }
          }}
        >
          Добавить
        </Button>
      </Space>
    </Space>
  );
};

export default RelatedPagesTab;
