import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { userApi } from '../api/apis';

export interface GeneralState {
  value: number;
  initial: boolean;
  token: string;
  username: string;
  password: string;
  isAuthorized: boolean;
  name: string;
}

const initialState: GeneralState = {
  value: 0,
  username: '',
  password: '',
  isAuthorized: false,
  name: '',
  initial: true,
  token: localStorage.getItem('token'),
};

export const fetchUserInfo = createAsyncThunk(
  'general/fetchUserInfo',
  async (token: string, { rejectWithValue }) => (
    userApi
      .apiUserGetMeGet({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response.data
      })
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      .catch((e) => rejectWithValue(e.message))),
);

export const login = createAsyncThunk(
  'general/login',
  async (
    payload: { username: string; password: string },
    { rejectWithValue },
  ) => {
    const response = await userApi.apiUserLoginPost({
      username: payload.username,
      password: payload.password,
    });

    if (response.data.token) {
      return response.data;
    }
    return rejectWithValue('asdf'); // todo
  },
);

export const generalSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    changeUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
    changePassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    logout: (state) => {
      state.token = undefined;
      localStorage.removeItem('token');
      window.location.replace('/login');
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        state.name = action.payload; // todo
        state.isAuthorized = true;
        state.initial = false;
      })
      .addCase(fetchUserInfo.rejected, (state) => {
        // Add user to the state array
        // state.entities.push(action.payload)
        state.initial = false;
        state.token = undefined;
      })
      .addCase(login.fulfilled, (state, action) => {
        // Add user to the state array
        state.token = action.payload.token;
        // state.name = action.payload.username

        // store token
        localStorage.setItem('token', state.token);

        //
      })
      .addCase(login.rejected, () => {
        // console.log(action.error.message); //todo
      });
    // todo add cases (errors etc)
  },
});

// Action creators are generated for each case reducer function
export const {
  changeUsername,
  changePassword,
  logout,
} = generalSlice.actions;

export default generalSlice.reducer;
