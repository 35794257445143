import { Tree } from 'antd'
import * as React from 'react'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { useEffect } from 'react'
import { getAllBlocks } from '../../store/slices/block/blocksActions'
import { BlockDto } from '../../generated/backend'
import TreeItem from '../common/forTree/TreeItem'
import { useNavigate } from 'react-router-dom'
import { blockApi, fileApi } from '../../api/apis'
import { Loader } from '../common/Loader/Loader'

const BlockTree = () => {
  const { blocks, loading } = useAppSelector(state => state.blocks.tree);
  const { block } = useAppSelector(state => state.blocks.selected);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onRemove = async (item: BlockDto) => {
    navigate('/blocks');

    const block = await blockApi.apiBlockIdGet(`${item.id}`);

    if (block.data.files.length !== 0) {
      for (let i = 0; i < block.data.files.length; i++) {
        await fileApi.apiFileIdDelete(`${block.data.files[i].id}`);
      }

      await blockApi.apiBlockIdDelete(`${block.data.id}`);
      await dispatch(getAllBlocks());

      return;
    }

    await blockApi.apiBlockIdDelete(`${item.id}`);
    await dispatch(getAllBlocks());
  };

  useEffect(() => {
    (async () => {
      await dispatch(getAllBlocks());
    })()
  }, [dispatch]);

  const blockToTreeItem = (block: BlockDto) => ({
    ...block,
    key: block.id,
    title: <TreeItem itemName={block.name} onDelete={async () => await onRemove(block)} />,
  });

  const onSelect = (item) => {
    navigate(`/blocks/${item.id}`);
  };

  if (loading && !blocks.length) {
    return <Loader />
  }

  return (
    <Tree
      selectedKeys={block ? [block.id] : []}
      treeData={blocks.map(blockToTreeItem)}
      onSelect={(key, info) => {
        onSelect(info.node);
      }}
    />
  );
};

export default BlockTree;
