import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { Button, message, Space, UploadProps } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FileImageOutlined, HighlightOutlined,
  InboxOutlined,
  SlidersOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons'
import Dragger from 'antd/es/upload/Dragger';
import styles from './MdEdit.module.scss';
import MDEditor, {
  commands,
  ICommandChildHandle,
  TextAreaTextApi,
  TextState
} from '@uiw/react-md-editor';
import '@uiw/react-md-editor/markdown-editor.css';
import { Code } from '../Code';
import axios from 'axios';
import { FileDto, PageDto } from '../../../generated/backend';
import { Buffer } from 'buffer';
import { Loader } from '../Loader/Loader';
import FileTable from './fileTable/FileTable';
import { fileApi, newsPageApi, pageApi } from '../../../api/apis';
import { useAppDispatch } from '../../../store/store'
import { setCurrentPageName } from '../../../store/slices/layout/layoutSlice'
import { RcFile } from 'antd/es/upload'

export const MdEdit: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [text, setText] = useState<string>('');
  const [data, setData] = useState<PageDto>();
  const [files, setFiles] = useState([]);
  const [isPage, setIsPage] = useState<boolean>(true);
  const [loadingFiles, setLoadingFiles] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const fetchPage = async () => {
    if (location.pathname.split('/')[2] === 'page') {
      const response = await pageApi.apiPageIdGet(id);

      setData(response.data);
      setText(response.data.text);
      setLoadingFiles(true);
      dispatch(setCurrentPageName(response.data.name));
    } else {
      const response = await newsPageApi.apiNewsPageIdGet(id);
      setIsPage(false);
      setData(response.data);
      setText(response.data.text);
      setLoadingFiles(true);
      dispatch(setCurrentPageName(response.data.name));
    }
  };

  const fetchFiles = async () => {
    const files = data?.files;
    const result: FileDto[] = [];

    for (let i = 0; i < files?.length; i++) {
      const response = await fileApi.apiFileDownloadGet(files[i].id);

      if (response.data.data) {
        const file = Buffer.from(response.data.data, 'binary').toString(
          'base64'
        );

        result.push({
          id: files[i].id,
          name: files[i].name,
          url: `data:image/png;base64,${file}`
        });

        continue;
      }

      const res = await axios.get(
        `/api/file/download?id=${files[i].id}`,
        {
          responseType: 'arraybuffer'
        }
      );

      const file = Buffer.from(res.data, 'binary').toString('base64');

      result.push({
        id: files[i].id,
        name: files[i].name,
        url: `data:image/png;base64,${file}`
      });
    }

    setFiles(result);
    setLoadingFiles(false);
  };

  useEffect(() => {
    (async () => {
      await fetchPage();
    })();
  }, [id]);

  useEffect(() => {
    (async () => {
      await fetchFiles();
    })();
  }, [loadingFiles]);

  const Image = props => {
    const imageSrc = files?.find(item => item.name === props.src);

    return <img src={imageSrc?.url} alt={props.alt} />;
  };

  const props: UploadProps = {
    multiple: true,
    type: 'drag',
    progress: {
      format: percent => percent && `${parseFloat(percent.toFixed(2))}%`
    },
    customRequest: async info => {
      const { onSuccess, onProgress } = info;
      const file = info.file as RcFile;

      if (typeof file === 'string') return;

      const fmData = new FormData();

      fmData.append('file', file, file.name);

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          Size: file.size
        },
        onUploadProgress: event => {
          onProgress({ percent: (event.loaded / event.total) * 100 });
        }
      };

      try {
        const save = await axios.post(
          `/api/file/upload?entityId=${id}&entityType=${
            isPage ? 'Page' : 'NewsPage'
          }`,
          fmData,
          config
        );

        onSuccess('Ok');

        await fetchPage();
        await fetchFiles();

        message.success('Файл успешно загружен');
      } catch (err) {
        message.error('Что-то пошло не так');
      }
    }
  };

  const addImage = (name: string, api: TextAreaTextApi) => {
    const modifyText = `![](${name})`;
    api.replaceSelection(modifyText);
  };

  const imageCommand: ICommandChildHandle = {
    name: 'getImage',
    groupName: 'getImage',
    icon: <FileImageOutlined rev={undefined} />,
    children: handle => {
      return (
        <div style={{ width: 300, padding: 10, height: 200 }}>
          <div>Прикрепленные файлы</div>
          <div className={styles.imagesContainer}>
            {files.map((item, index) => {
              return (
                <div
                  className={styles.image}
                  key={index}
                  onClick={() =>
                    addImage(item.name, handle.textApi)
                  }
                >
                  <img
                    src={item.url}
                    alt=''
                    style={{
                      objectFit: 'cover',
                      width: '100%',
                      height: '100%'
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    },
    buttonProps: { title: 'Добавить изображение из файлов' }
  };

  const videoCommand: ICommandChildHandle = {
    name: 'getVideo',
    groupName: 'getVideo',
    icon: <VideoCameraOutlined rev={undefined} />,
    execute: (state: TextState, api: TextAreaTextApi) => {
      const modifyText = '\\<VideoPlayer /\\>';

      api.replaceSelection(modifyText);
    },
    buttonProps: { title: 'Добавить видео тег (VideoPlayer)' }
  };

  const sliderCommand: ICommandChildHandle = {
    name: 'sliderContent',
    groupName: 'sliderContent',
    icon: <SlidersOutlined rev={undefined} />,
    execute: (state: TextState, api: TextAreaTextApi) => {
      const modifyText =
                '\\<ContentSlider images={[\'example.png\']} sizes={[{\'width\': 960, \'height\':1280}]} /\\>';

      api.replaceSelection(modifyText);
    },
    buttonProps: { title: 'Добавить слайдер тег (ContentSlider)' }
  };

  const highlightCommand: ICommandChildHandle = {
    name: 'setHighlight',
    groupName: 'setHighlight',
    icon: <HighlightOutlined rev="" />,
    execute: (state, api) => {
      const titleLevel = Array.from(state.selectedText).filter((item) => item === '#').length;
      let modifyText = state.selectedText;
      if (titleLevel === 0) {
        modifyText =
          '\\<p class="decor-container">\\<span class="decor_text">'+ state.selectedText.replaceAll('#', '').replace(' ', '') +'\\</span>\\</p>'
      } else if (titleLevel > 0) {
        modifyText = '\\<h' + titleLevel.toString() + ' class="decor-container"' + '>\\<span class="decor_text">'+ state.selectedText.replaceAll('#', '').replace(' ', '') +
          '\\</span>' + '\\</h' +
          titleLevel.toString() +
          '>'
      }
      api.replaceSelection(modifyText)
    },
    buttonProps: { title: 'Выделить подзаголовок' }
  };

  const onSave = async () => {
    if (isPage) await pageApi.apiPagePut(data);
    else await newsPageApi.apiNewsPagePut(data);
    await fetchPage();
  };

  if (!data) {
    return <Loader />;
  }

  const setFieldValue = (key: string, value: string) => {
    setData({
      ...data,
      [key]: value
    });
  };

  return (
    <>
      <div className={styles.container}>
        <Space style={{ marginBottom: 12 }}>
          <Button type={'primary'} onClick={onSave}>
                        Сохранить
          </Button>
          <Button onClick={() => navigate(-1)}>Закрыть</Button>
        </Space>
        <div data-color-mode='light'>
          <MDEditor
            height={400}
            value={text}
            onChange={text => {
              setText(text);
              setFieldValue('text', text);
            }}
            previewOptions={{
              components: {
                code: Code,
                img: Image
              }
            }}
            commands={[
              ...commands.getCommands(),
              commands.divider,
              commands.group([], imageCommand),
              commands.group([], videoCommand),
              commands.group([], sliderCommand),
              commands.group([], highlightCommand),
            ]}
          />
        </div>
        <div style={{ marginTop: '12px' }}>
          <FileTable files={data?.files || []}
            fetchPage={fetchPage}
            isPage={isPage}
            pageId={id}
          />
        </div>
        <div className={styles.uploadContainer}>
          <Dragger {...props} style={{ width: 300 }}>
            <p className='ant-upload-drag-icon'>
              <InboxOutlined rev={undefined} />
            </p>
            <p className='ant-upload-text'>
                            Нажмите или перенесите файл
            </p>
          </Dragger>
        </div>
      </div>
    </>
  );
};
