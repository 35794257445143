import { createSlice } from '@reduxjs/toolkit'
import { PartnersState } from './types'
import { getAllPartners, getPartner } from './partnersActions'

const initialState: PartnersState = {
  selected: {
    loading: true,
    partner: {}
  },
  tree: {
    loading: true,
    partners: []
  }

}

const partnersSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    clearSelected: (state) => {
      state.selected.partner = {};
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPartners.pending, (state) => {
      state.tree.loading = true;
    });
    builder.addCase(getAllPartners.fulfilled, (state, action) => {
      state.tree.partners = action.payload;
      state.tree.loading = false;
    });

    builder.addCase(getPartner.pending, (state) => {
      state.selected.loading = true;
    });
    builder.addCase(getPartner.fulfilled, (state, action) => {
      state.selected.partner = action.payload;
      state.selected.loading = false;
    })
  },
});

export default partnersSlice.reducer;

export const { clearSelected } = partnersSlice.actions;
