import { Table } from 'antd'
import { columnsForFiles } from '../../helpers'
import * as React from 'react'
import { fileApi } from '../../api/apis'
import { useAppDispatch } from '../../store/store'
import { getReview } from '../../store/slices/reviews/reviewsActions'
import { FC } from 'react'
import { ReviewDto } from '../../generated/backend'

interface ReviewFilesTable {
  id: string;
  data: ReviewDto;
}

const ReviewFilesTable: FC<ReviewFilesTable> = (props) => {
  const { id, data} = props;
  const dispatch = useAppDispatch();

  const onRemove = async (fileId: string) => {
    await fileApi.apiFileIdDelete(fileId);
    await dispatch(getReview(Number(id)));
  };

  return (
    <Table
      columns={columnsForFiles(onRemove)}
      dataSource={data?.files?.map((item, index) => ({
        ...item,
        key: index + 1
      }))}
      size={'small'}
      pagination={false}
    />
  );
};

export default ReviewFilesTable;
