import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Form, message, Row, } from 'antd';
import { Loader } from '../common/Loader/Loader';
import { PageDto } from '../../generated/backend'
import TabsBlock from './pagesForm/TabsBlock';
import FormInput from '../common/forForm/FormInput';
import SelectorWithSearch from './pagesForm/SelectorWithSearch';
import SectionSelector from './pagesForm/SectionSelector';
import { useAppDispatch, useAppSelector } from '../../store/store'
import { getAllPages, getPageById, updatePage } from '../../store/slices/pages/pagesActions'
import SimpleSelector from '../common/forForm/SimpleSelector'
import ButtonGroup from '../common/forForm/ButtonGroup'
import { setSelectedPage } from '../../store/slices/pages/pagesSlice'

const PageForm = () => {
  const { page, loading } = useAppSelector((state) => state.pages.selected);
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState<PageDto>(null);
  const dispatch = useAppDispatch();
  const expanded = JSON.parse(localStorage.getItem('pages'));

  useEffect(() => {
    dispatch(getPageById(Number(id)));
    return () => {
      dispatch(setSelectedPage(null))
    }
  }, [dispatch, id]);

  useEffect(() => {
    setData(page);
  }, [page]);

  const onSubmit = async () => {
    await dispatch(updatePage(data));
    message.success('Сохранено!');
    await dispatch(getAllPages(expanded));
    // todo убрать после выполнения задачи CMS-111 Update PageDto
    await dispatch(getPageById(Number(id)))
  };

  const onCloseHandler = () => {
    navigate('/pages');
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [e.target.name]: e.target.value || '',
    })
  };

  const onSelectChange = (value:  Partial<PageDto>) => {
    setData({
      ...data,
      ...value,
    })
  };

  if (!data || loading) {
    return <Loader/>;
  }

  return (
    <Form labelCol={{ span: 2, offset: 0 }} labelAlign={'left'}>

      <ButtonGroup onSubmit={onSubmit} onClose={onCloseHandler} onEdit={() => navigate(`/edit/page/${id}`)} />

      <Row>
        <FormInput fieldName="name" value={data?.name} onChange={onInputChange} />

        <SelectorWithSearch
          entityType="page" value={data?.parent?.name || data?.parent?.id || data?.parentId}
          onSelectChange={onSelectChange}
          labelName="parent"
        />

        <SectionSelector values={data?.sections} onChange={onSelectChange} />

        <FormInput fieldName={'slug'} value={data?.slug} onChange={onInputChange} />

        <FormInput fieldName={'youtrackId'} value={data?.youtrackId} onChange={onInputChange} />

        <SimpleSelector
          label="status"
          value={data?.status?.value || 'DRAFT'}
          onChange={onSelectChange}
          options={data?.statuses}
        />

        <FormInput fieldName={'metaTitle'} value={data?.metaTitle} onChange={onInputChange} />

        <FormInput fieldName={'metaDescription'} value={data?.metaDescription} onChange={onInputChange} />

        <FormInput fieldName={'brief'} value={data?.brief} onChange={onInputChange} maxLength={500} />

        <FormInput fieldName={'htmlBlock1'} value={data?.htmlBlock1} onChange={onInputChange} />

        <FormInput fieldName={'topHeading'} value={data?.topHeading} onChange={onInputChange} />

        <FormInput fieldName={'topText1'} value={data?.topText1} onChange={onInputChange} />

        <FormInput fieldName={'topText2'} value={data?.topText2} onChange={onInputChange} />

        <FormInput fieldName={'topCellName1'} value={data?.topCellName1} onChange={onInputChange} small />

        <FormInput fieldName={'topCellName2'} value={data?.topCellName2} onChange={onInputChange} small />

        <FormInput fieldName={'topCellName3'} value={data?.topCellName3} onChange={onInputChange} small />

        <FormInput fieldName={'topCellValue1'} value={data?.topCellValue1} onChange={onInputChange} small />

        <FormInput fieldName={'topCellValue2'} value={data?.topCellValue2} onChange={onInputChange} small />

        <FormInput fieldName={'topCellValue3'} value={data?.topCellValue3} onChange={onInputChange} small />

        <FormInput fieldName={'topComment'} value={data?.topComment} onChange={onInputChange} medium />

        <FormInput fieldName={'callToAction'} value={data?.callToAction} onChange={onInputChange} medium />
      </Row>

      <Row>
        <Col>
          <div style={{ marginBottom: '12px' }}>
            <TabsBlock id={id} />
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default PageForm;
