import { createAsyncThunk } from '@reduxjs/toolkit'
import { fileApi, newsPageApi } from '../../../api/apis'

export const getAllNewsPages = createAsyncThunk(
  'news/getAll',
  async (_, { rejectWithValue }) => {
    try {
      const res = await newsPageApi.apiNewsPageGet();
      return res.data;
    } catch (e) {
      rejectWithValue(e.message);
    }
  },
);

export const getNewsPageById = createAsyncThunk(
  'news/getById',
  async (id: number, { rejectWithValue })=> {
    try {
      const res = await newsPageApi.apiNewsPageIdGet(id.toString());
      return res.data;
    } catch (e) {
      rejectWithValue(e.message);
    }
  }
);

export const deleteNewsPage = createAsyncThunk(
  'news/delete',
  async (id: number, { rejectWithValue }) => {
    try {
      const news = await newsPageApi.apiNewsPageIdGet(id.toString());
      if (news.data.files.length > 0) {
        await Promise.all(news.data.files.map(file => {
          fileApi.apiFileIdDelete(file.id);
        }));
      }
      await newsPageApi.apiNewsPageIdDelete(id.toString());
    } catch (e) {
      rejectWithValue(e.message);
    }
  }
);
