import { Dropdown, MenuProps, Space } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import React, { FC } from 'react';

export const DropDown: FC = <TypeOfItem,>(
  item: TypeOfItem,
  onDelete: (item: TypeOfItem) => Promise<void>
) => {
  const items: MenuProps['items'] = [
    {
      label: (
        <span onClick={async () => await onDelete(item)}>Удалить</span>
      ),
      key: '0',
      danger: true
    }
  ];
  return (
    <>
      <Dropdown menu={{ items }} trigger={['click']}>
        <a onClick={e => e.preventDefault()}>
          <Space>
            <MoreOutlined rev={undefined} />
          </Space>
        </a>
      </Dropdown>
    </>
  );
};
