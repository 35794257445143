import { Button } from 'antd';
import * as React from 'react';

export const setFieldValue = <T, K>(field: string, value: T, data: K) => {
    return { ...data, [field]: value };
};

export const columnsForFiles = <TypeOfId,>(
    onRemove: (id: TypeOfId) => void
) => {
    return [
        { key: 'key', title: '№', dataIndex: 'key' },
        { key: 'filename', title: 'File Name', dataIndex: 'name' },
        {
            key: 'actions',
            title: 'Actions',
            render: (_, record) => (
                <Button type={'text'} onClick={() => onRemove(record.id)}>
                    Удалить
                </Button>
            )
        }
    ];
};
