import { createAsyncThunk } from '@reduxjs/toolkit'
import { blockApi } from '../../../api/apis'

export const getAllBlocks = createAsyncThunk(
  'blocks/getAll',
  async (_, { rejectWithValue }) => {
    try {
      const res = await blockApi.apiBlockGet();
      return res.data;
    } catch (e) {
      rejectWithValue(e.message);
    }
  }
);

export const getBlock = createAsyncThunk(
  'blocks/getById',
  async (id: number, { rejectWithValue }) => {
    try {
      const res = await blockApi.apiBlockIdGet(id.toString());
      return res.data;
    } catch (e) {
      rejectWithValue(e.message);
    }
  }
)
