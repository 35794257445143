import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Col, Row, message } from 'antd';
import { SettingDto } from '../../generated/backend';
import AddSettingModal from './AddSettingModal';
import SettingsTree from './SettingsTree'
import { settingApi } from '../../api/apis'
import { useAppDispatch } from '../../store/store'
import { getAllSettings } from '../../store/slices/settings/settingsActions'
import AddButton from '../common/forTree/AddButton'

const SettingsLayout = () => {
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const dispatch = useAppDispatch();

  const createSetting = async (setting: SettingDto) => {
    try {
      const res = await settingApi.apiSettingPost(setting);
      return res.data;
    } catch (e) {
      message.error(e.message);
    }
  };

  const onSaveDialog = async (setting: SettingDto) => {
    const createdSetting = await createSetting(setting);
    setShowDialog(false);
    message.success('Сохранено!');
    await dispatch(getAllSettings());
    navigate(`/settings/${createdSetting.id}`);
  };

  const onAddHandler = () => {
    setShowDialog(true);
  };

  const onCloseDialog = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Row justify={'space-around'}>
        <Col span={8}>
          <AddButton onClick={onAddHandler} />
          <SettingsTree />
        </Col>
        <Col span={15}>
          <Outlet />
        </Col>
      </Row>
      {showDialog && <AddSettingModal
        isOpen={showDialog}
        onClose={onCloseDialog}
        onSave={onSaveDialog}
      />}
    </>
  );
};

export default SettingsLayout;
