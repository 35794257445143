import React, { useState } from 'react'
import { Outlet, } from 'react-router-dom'
import { Col, Row, Space } from 'antd'
import MenuTree from './menuTree/MenuTree'
import MenuAddDialog from './menuAddDialog/MenuAddDialog'
import AddButton from '../common/forTree/AddButton'
import { useLocalStorage } from 'usehooks-ts'

const MenuItemLayout = () => {
  const [showDialog, setShowDialog] = useState(false)
  const [expandedKeys, setExpandedKeys] = useLocalStorage<number[]>('menu', []);

  const onAddHandler = async () => {
    setShowDialog(true)
  };

  return (
    <div className={'content-wrapper'}>
      <Row justify={'space-around'}>
        <Col span={6}>
          <Space>
            <AddButton onClick={onAddHandler} />
          </Space>
          <MenuTree
            expandedKeys={expandedKeys}
            updateExpandedKeys={setExpandedKeys}
          />
        </Col>
        <Col span={17}>
          <Outlet />
        </Col>
      </Row>
      {showDialog && <MenuAddDialog
        closeModal={() => setShowDialog(false)}
        isOpen={showDialog}
        expandedKeys={expandedKeys}
        updateExpandedKeys={setExpandedKeys}
      />}
    </div>
  )
}

export default MenuItemLayout
