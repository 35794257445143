import React, { FC, useEffect } from 'react'
import { Tree } from 'antd'
import { TreeItemDto } from '../../../generated/backend';
import { DataNode } from 'rc-tree/lib/interface';
import TreeItem from '../../common/forTree/TreeItem'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { listToTree } from '../../common/forTree/listToTree'
import { deleteMenuItem, getMenuTree } from '../../../store/slices/menu/menuActions'
import { useNavigate } from 'react-router-dom'
import { Loader } from '../../common/Loader/Loader'

interface MenuTree {
  updateExpandedKeys: (_keys: number[]) => void;
  expandedKeys: number[];
}

const MenuTree: FC<MenuTree> = (props) => {
  const { expandedKeys, updateExpandedKeys, } = props;
  const { items, loading,  } = useAppSelector((state) => state.menu.tree);
  const { item } = useAppSelector(state => state.menu.selected)
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const deleteItem = async (menuItem: TreeItemDto) => {
    await dispatch(deleteMenuItem(menuItem.id));
    navigate('/menu');
    await dispatch(getMenuTree(expandedKeys));
  };

  const menuToNode = (menuItem: TreeItemDto): DataNode => (
    {
      ...menuItem,
      isLeaf: !menuItem.collapsible,
      key: menuItem.id,
      selectable: !menuItem.selectable,
      title: <TreeItem itemName={menuItem.name} onDelete={async () => await deleteItem(menuItem)} />,
    }
  );

  const getTreeData = () => {
    const nodes = items.map(menuToNode);
    console.log(items)
    return listToTree(nodes);
  };

  const onExpand = (keys: number[]) => {
    updateExpandedKeys(keys);
  };

  const onSelect = (key: number[]) => {
    navigate(`/menu/${key[0]}`);
  };

  useEffect(() => {
    (async () => {
      await dispatch(getMenuTree(expandedKeys));
    })()
  }, [dispatch, expandedKeys]);

  if (loading && !items.length) {
    return <Loader />
  }

  return (
    <Tree
      treeData={getTreeData()}
      expandedKeys={expandedKeys}
      autoExpandParent={false}
      selectedKeys={item ? [item.id] : []}
      onExpand={onExpand}
      onSelect={onSelect}
      showLine
    />
  );
};

export default MenuTree;
